@mixin optimizer-text-shadow(
    $color: $color_background-200,
    $text-shadow-offset: 0 0 25px,
    $step: 5
) {
    @for $i from 0 through ceil(100 / $step) {
        $value: $i * $step;

        &-#{$value} {
            text-shadow: #{$text-shadow-offset} rgba($color, ($value / 100));
        }
    }
}
