@import 'components/teaser/hooks/pagination.scss';

// Nav buttons
$teaser_hide-nav-buttons-on-mobile: true !default;
$teaser_nav-button-position: absolute !default;
$teaser_nav-button-position-top: 50% !default; // 2rem recompenses height of the bullet-pagination
$teaser_nav-button-z-index: 201 !default;
$teaser_nav-button-transform: translate3d(0, -50%, 0) !default;
$teaser_nav-button-transition: all 0.15s linear !default;
$teaser_nav-button-width: 5.5rem !default;
$teaser_nav-button-height: $teaser_nav-button-width !default;
$teaser_nav-button-border: 0 !default;
$teaser_nav-button-background: $color_background-200 !default;
$teaser_nav-button-box-shadow: 1px 1px 2px 0 rgba($color_black, 0.25) !default;
$teaser_nav-button-border-radius: 2 * $border-radius_base !default;
$teaser_nav-button-padding: 0 !default;
$teaser_nav-button-disabled-display: none !default;
$teaser_nav-button-disabled-opacity: 1 !default;
$teaser_nav-button-horizontal-position: 1.5rem !default;
$teaser_nav-button-horizontal-offset-breakpoint: '>=laptopLg' !default;
$teaser_nav-button-horizontal-offset-desktop: -#{$teaser_nav-button-width / 2} !default;
// Hover
$teaser_nav-button--hover-box-shadow: 1px 1px 10px 0 rgba($color_black, 0.25) !default;
$teaser_nav-button--hover-border: $teaser_nav-button-border !default;
$teaser_nav-button--hover-background: $teaser_nav-button-background !default;
$teaser_nav-button--hover-additional-transform: '' !default;

// Nav buttons icons
$teaser_nav-button-icon-width: 1.4rem !default;
$teaser_nav-button-icon-height: 2.7rem !default;
$teaser_nav-button-icon-color: $color_primary-500 !default;
// Hover
$teaser_nav-button-icon--hover-color: $teaser_nav-button-icon-color !default;

// Fraction pagination (number pagination)
$teaser_pagination-numbers-font-weight: 700 !default;
$teaser_pagination-number--current-color: $color_primary-500 !default;

// Pagination list
$teaser_pagination-margin: 0 !default;
$teaser_pagination-padding: 0.5rem 0 0 !default;
$teaser_pagination-min-height: 3rem !default;
$teaser_pagination-justify-content: center !default;
$teaser_pagination-align-items: center !default;
$teaser_pagination-text-color: $color_text-500 !default; // used for "fraction" pagination (numbers)
$teaser_pagination-z-index: 200 !default;

@mixin bullet-pagination-list {
    @include reset-list-style();
    margin: $teaser_pagination-margin;
    padding: $teaser_pagination-padding;
    min-height: $teaser_pagination-min-height;
    display: flex;
    flex-wrap: wrap;
    justify-content: $teaser_pagination-justify-content;
    align-items: $teaser_pagination-align-items;
    color: $teaser_pagination-text-color;
    z-index: $teaser_pagination-z-index;

    &--hidden {
        display: none;
    }

    @include bullet-pagination-list-hook();
}

// Pagination item
$teaser_pagination-item-margin: 0.4rem !default;
$teaser_pagination-item-border-radius: 2px !default;
$teaser_pagination-item-background: $color_gallery !default;
$teaser_pagination-item-width: 2.2rem !default;
$teaser_pagination-item-height: 0.9rem !default;
$teaser_pagination-item-transform: $transform_skew-400 !default;
$teaser_pagination-item-border: none !default;
$teaser_pagination-item-transition: all 0.15s !default;
$teaser_pagination-item--hover-background: $color_alto !default;

@mixin bullet-pagination-item {
    position: relative;
    margin: $teaser_pagination-item-margin;
    border-radius: $teaser_pagination-item-border-radius;
    background: $teaser_pagination-item-background;
    width: $teaser_pagination-item-width;
    height: $teaser_pagination-item-height;
    transform: $teaser_pagination-item-transform;
    border: $teaser_pagination-item-border;
    transition: $teaser_pagination-item-transition;

    &:hover {
        background: $teaser_pagination-item--hover-background;
    }

    @include bullet-pagination-item-hook();
}

$teaser_pagination-item--active-background: $color_gold !default;
@mixin bullet-pagination-item--active {
    &,
    &:hover {
        background: $teaser_pagination-item--active-background;
    }

    @include bullet-pagination-item--active-hook();
}

@mixin bullet-pagination-button {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    margin: 0;
    cursor: pointer;

    @include bullet-pagination-button-hook();
}
