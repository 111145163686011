@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

$tile-gallery_postion-left: 0 !default;
$tile-gallery_postion-right: auto !default;
$tile-gallery_postion-top: auto !default;
$tile-gallery_postion-bottom: 0 !default;
$tile-gallery_zindex: 4 !default;
$tile-gallery_transition: opacity 0.15s linear !default;

$tile-gallery_item-border-color: $color_border-700 !default;
$tile-gallery_item--active-border-color: $color_primary-800 !default;
$tile-gallery_item-border: 1px solid $tile-gallery_item-border-color !default;
$tile-gallery_item-transition: border-color 0.15s linear !default;

$tile-gallery_thumbnail-width: 4rem !default;
$tile-gallery_thumbnail-height: 4rem !default;

$tile-gallery_spinner-image-url: '../images/icons/tile-gallery/tile-gallery-spinner.svg' !default;
$tile-gallery_spinner-background-color: $color_background-200;

$tile-gallery_main-image-animatable-opacity: 0 !default;
$tile-gallery_main-image-animate-opacity: 1 !default;
$tile-gallery_main-image-animatable-transform: scale(2.5) !default;
$tile-gallery_main-image-animate-transform: scale(1) !default;
$tile-gallery_main-image-transition: transform 0.5s
        $transition-easing_slowdown-900,
    opacity 0.45s linear !default;

.#{$ns}tile-gallery {
    $root: &;

    @include reset-list-style();
    display: none;
    position: absolute;
    z-index: $tile-gallery_zindex;
    left: $tile-gallery_postion-left;
    right: $tile-gallery_postion-right;
    top: $tile-gallery_postion-top;
    bottom: $tile-gallery_postion-bottom;
    opacity: 0;
    transition: $tile-gallery_transition;

    @include media('>=laptop') {
        display: flex;
    }

    .touch-device & {
        display: none !important;
    }

    &.active {
        .#{$ns}product-tile:hover & {
            @include media('>=laptop') {
                opacity: 1;
            }
        }
    }

    &__item {
        margin: 0 0.3rem 0 0;
        padding: 0;
        cursor: pointer;
    }

    &__thumbnail {
        display: block;
        width: $tile-gallery_thumbnail-width;
        height: $tile-gallery_thumbnail-height;
        border: $tile-gallery_item-border;
        transition: $tile-gallery_item-transition;
        overflow: hidden; // Fix source elements overflowing picture element's border
        position: relative;
        background-image: url($tile-gallery_spinner-image-url);
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-color: $color_background-200;

        #{$root}__item--active & {
            border-color: $tile-gallery_item--active-border-color;
        }

        > source,
        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.#{$ns}product-tile {
    &__image {
        &--animatable {
            opacity: $tile-gallery_main-image-animatable-opacity;
            transform: $tile-gallery_main-image-animatable-transform;
            transition: $tile-gallery_main-image-transition;
        }

        &--animate {
            opacity: $tile-gallery_main-image-animate-opacity;
            transform: $tile-gallery_main-image-animate-transform;
        }
    }
}
