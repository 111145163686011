@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

$footer-links_list-item-margin: 0 0 0.3em !default;

$footer-links_link-font-size: 14px !default;
$footer-links_link-color: $color_mineshaft !default;
$footer-links_link-hover-color: darken($footer-links_link-color, 15%) !default;
$footer-links_link-hover-decoration: underline !default;
$footer-links_link-padding-mobile: 0.5em 0 !default;
$footer-links_link-padding-desktop: 0.35em 0 !default;

.#{$ns}footer-links {
    $root: &;

    @include reset-list-style();

    &__item {
        margin: $footer-links_list-item-margin;
    }

    a,
    a:visited {
        display: block;
        padding: $footer-links_link-padding-mobile;
        font-size: $footer-links_link-font-size;
        color: $footer-links_link-color;

        &:hover {
            color: $footer-links_link-hover-color;
            text-decoration: $footer-links_link-hover-decoration;
        }

        @include media('>=tablet') {
            padding: $footer-links_link-padding-desktop;
            display: inline-block;
        }
    }
}

.cs-social-media-list__link {
  color: $color_cerulean-blue;

  &::hover {
    color: $footer-links_link-color;
  }
}
