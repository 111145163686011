@import 'vendors/include-media';

// TODO: Remove after getting rid of these from templates
.cs-hide-laptop {
    @include media('>=laptop', '<laptopLg') {
        display: none !important; /* stylelint-disable-line declaration-no-important */
    }
}

.cs-hide-tablet {
    @include media('>=tablet', '<laptop') {
        display: none !important; /* stylelint-disable-line declaration-no-important */
    }
}

.cs-no-display {
    display: none !important; /* stylelint-disable-line declaration-no-important */
}

.cs-aspect-ratio {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
