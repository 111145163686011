/* stylelint-disable block-no-empty */
@mixin qty-counter_hook($type) {
}

@mixin qty-counter_type-hook($type) {
}

@mixin qty-counter_span-hook($type) {
}
/* stylelint-enable */
