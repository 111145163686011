// price-box
// This component is responsible for styling prices by markup provided by Magento2
//
// See http://warpspire.com/kss/ for documentation guidelines.
// Version 0.0.1
@mixin priceBox() {
    display: inline-block;

    .price {
        font-weight: 700;
        white-space: nowrap;

        &-label {
            display: none;
        }
    }

    .old-price {
        .price {
            font-size: 0.65rem;
            text-decoration: line-through;
        }

        // Old price should not be displayed for prices where normal price is "As low as {price}"
        &.no-display {
            display: none;
        }
    }

    .special-price .price {
        padding-left: 0.4em;
    }
}
