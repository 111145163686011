@import 'config/variables';
@import 'vendors/include-media';

@import 'components/button/mixin';

$teaser_button-type: $button_default-type !default;
$teaser_button-icon-pos: $button_default-icon-pos !default;

.#{$ns}navigation-teaser {
    &__figure {
        display: block;
        max-height: 100%;
        overflow: hidden;
    }

    &__image {
        display: block;
    }

    &__content-wrapper {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 6rem 3rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        color: $color_mineshaft;
        text-align: center;
    }

    &__headline {
        margin: 0 0 1rem;
        font-size: 3rem;
        line-height: 1.15;
        font-weight: 700;
        color: $color_mineshaft;

        @include media('>=laptop') {
            font-size: 4.3rem;
            line-height: 1;
        }
    }

    &__subheadline {
        margin: 0 0 1rem;
        font-size: 1.65rem;
        line-height: 1.15;
        font-weight: 400;
        color: $color_mineshaft;

        @include media('>=laptop') {
            font-size: 2.8rem;
            line-height: 1.25;
        }
    }

    &__text {
        margin: 0 0 1rem;
        font-size: 1rem;
        line-height: 1.15;
        font-weight: 300;
        color: $color_mineshaft;

        @include media('>=laptop') {
            font-size: 1.4rem;
        }
    }

    &__cta {
        margin-top: 2rem;
    }

    &__button {
        @include button(
            $type: $teaser_button-type,
            $icon_pos: $teaser_button-icon-pos
        );
    }

    &__button-span {
        @include button_span(
            $type: $teaser_button-type,
            $icon_pos: $teaser_button-icon-pos
        );
    }

    &__button-icon {
        @include button_icon(
            $type: $teaser_button-type,
            $icon_pos: $teaser_button-icon-pos
        );
    }
}
