@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/badge/mixin';
@import 'components/button/mixin';
@import 'utils/reset-list-style';

$daily-deal-teaser_global-bold-font: 600 !default;
$daily-deal-teaser_global-small-font: 1.2rem !default;

$daily-deal-teaser_sections-border-separator: 1px solid $color_border-200 !default;
$daily-deal-teaser_sections-gutter-mobile: 1.5rem !default;
$daily-deal-teaser_sections-gutter-tablet-up: 2rem !default;

// Elements width
$daily-deal-teaser_aside-image-max-width-tablet: 32% !default;
$daily-deal-teaser_aside-image-max-width-desktop: 20% !default;

$daily-deal-teaser_main-max-width-tablet: 68% !default;
$daily-deal-teaser_main-max-width-desktop: 80% !default;

$daily-deal-teaser_details-max-width-tablet: 100% !default;
$daily-deal-teaser_details-max-width-desktop: 100% !default;

$daily-deal-teaser_actions-max-width-tablet: 100% !default;
$daily-deal-teaser_actions-max-width-desktop: 45% !default;

$daily-deal-teaser_product-dd-counter-width-tablet-up: 48% !default;

$daily-deal-teaser_price-wrapper-width: 30% !default;

$daily-deal-teaser_description-width-mobile: 100% !default;
$daily-deal-teaser_description-width-tablet-up: 45% !default;

// Addtocart - set to 100% to place button in separate row
$daily-deal-teaser_addtocart-wrapper-width-tablet-up: 47%;

//Product
$daily-deal-teaser_product-brand-color: $color_gray !default;
$daily-deal-teaser_product-name-margin: 0 0 0.5rem !default;
$daily-deal-teaser_product-name-font-size: 2.25rem !default;
$daily-deal-teaser_product-link-color: inherit !default;
$daily-deal-teaser_product-link--hover-color: $daily-deal-teaser_product-link-color !default;
$daily-deal-teaser_product-rating-margin: 0.2rem 0 1.5rem !default;
$daily-deal-teaser_product-dd-columns-gutter: $daily-deal-teaser_sections-gutter-mobile !default;
$daily-deal-teaser_product-dd-headline-font-size: $daily-deal-teaser_global-small-font !default;
$daily-deal-teaser_product-dd-headline-margin: 0 0 0.5rem !default;
$daily-deal-teaser_product-dd-headline-font-weight: $daily-deal-teaser_global-bold-font !default;
$daily-deal-teaser_product-dd-headline-text-transform: uppercase !default;

$daily-deal-teaser_product-dd-progress-bar-margin: 0.5rem 0 0 !default;
$daily-deal-teaser_product-dd-progress-bar-padding: 1.1rem 1rem !default;
$daily-deal-teaser_product-dd-progress-bar-background: $color_background-500 !default;
$daily-deal-teaser_product-dd-progress-bar-border-radius: $border-radius_base !default;
$daily-deal-teaser_product-dd-progress-bar-content-background: $color_white !default;
$daily-deal-teaser_product-dd-progress-bar-progress: $color_success-600 !default;
$daily-deal-teaser_product-dd-progress-bar-height: 10px !default;
$daily-deal-teaser_product-dd-progress-bar-possible-border: 1px solid
    $color_background-500 !default;
$daily-deal-teaser_product-dd-progress-bar-possible-margin: 2px !default;
$daily-deal-teaser_product-dd-progress-bar-possible-height: 80% !default;
$daily-deal-teaser_product-dd-progress-bar-possible-background: repeating-linear-gradient(
    -45deg,
    $color_border-500,
    $color_border-500 1px,
    transparent 1px,
    transparent 5px
) !default;

$daily-deal-teaser_product-dd-available-font-size: $daily-deal-teaser_global-small-font !default;
$daily-deal-teaser_product-dd-available-font-weight: $daily-deal-teaser_global-bold-font !default;

$daily-deal-teaser_price-details-height-laptop: auto !default;

//Badge position - true- position absolute on image, false- positioned above image
$daily-deal-teaser_discount-badge-position-absolute: true !default;
$daily-deal-teaser_discount-badge-font-size: 1.5rem !default;

$daily-deal-teaser_price-old-font-size: 1.4rem !default;
$daily-deal-teaser_price-final-font-size: 2.2rem !default;

$daily-deal-teaser_addtocart-button-type: $button_default-type !default;
$daily-deal-teaser_addtocart-button-icon-pos: $button_default-icon-pos !default;
$daily-deal-teaser_addtocart-margin-top: 1.5rem !default;
$daily-deal-teaser_addtocart-min-width-mobile: 20rem !default;
$daily-deal-teaser_addtocart-min-width: 17rem !default;
$daily-deal-teaser_addtocart-min-height: 4rem !default;

$daily-deal-teaser_description-list-items-limit-mobile-and-tablet: 6 !default;
$daily-deal-teaser_description-list-items-limit-desktop: 5 !default;
$daily-deal-teaser_description-list-items-padding-left: 2rem !default;
$daily-deal-teaser_description-list-icon-path: '../images/icons/check.svg' !default;
$daily-deal-teaser_description-list-item-font-size: 1.2rem !default;
$daily-deal-teaser_description-list-item-icon-width: 0.9rem !default;
$daily-deal-teaser_description-list-item-icon-height: $daily-deal-teaser_description-list-item-icon-width !default;

$daily-deal-teaser_product-dd-counter-min-height: $daily-deal-teaser_addtocart-min-height !default;

.#{$ns}daily-deal-teaser {
    $root: &;

    display: none; // Initially hidden because we want to make sure this offer is still available
    @include component-spacing();

    &__aside-image {
        display: flex;
        flex-direction: column;
        border-bottom: $daily-deal-teaser_sections-border-separator;
        padding: 0 0 $daily-deal-teaser_sections-gutter-mobile 0;

        @include media('>=tablet') {
            max-width: $daily-deal-teaser_aside-image-max-width-tablet;
            padding: 0 $daily-deal-teaser_sections-gutter-tablet-up 0 0;
            border-right: $daily-deal-teaser_sections-border-separator;
            border-bottom: none;
        }

        @include media('>=laptop') {
            flex: 1;
            border: none;
            max-width: $daily-deal-teaser_aside-image-max-width-desktop;
            border-right: $daily-deal-teaser_sections-border-separator;
        }
    }

    &__image-wrapper {
        width: 100%;

        &,
        &:visited,
        &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }

    &__figure {
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    &__main {
        width: 100%;
        flex-shrink: 1;

        @include media('>=tablet') {
            max-width: $daily-deal-teaser_main-max-width-tablet;
            padding: $daily-deal-teaser_sections-gutter-tablet-up;
        }

        @include media('>=laptop') {
            max-width: $daily-deal-teaser_main-max-width-desktop;
            flex: 1 1;
            display: flex;
            flex-wrap: wrap;
            padding: 0 $daily-deal-teaser_sections-gutter-tablet-up;
        }
    }

    &__details {
        border-bottom: $daily-deal-teaser_sections-border-separator;

        @include media('>=tablet') {
            flex-grow: 5;
            display: flex;
            flex-direction: row;
            max-width: $daily-deal-teaser_details-max-width-tablet;
        }

        @include media('>=laptop') {
            border-bottom: none;
            width: $daily-deal-teaser_details-max-width-desktop;
        }
    }

    &__description {
        width: $daily-deal-teaser_description-width-mobile;

        @include media('>=laptop') {
            width: $daily-deal-teaser_description-width-tablet-up;
        }

        @include media('>=laptopLg') {
            margin: 0 $daily-deal-teaser_sections-gutter-tablet-up 0 0;
        }
    }

    &__description-inner {
        margin: $daily-deal-teaser_sections-gutter-mobile 0;

        @include media('>=laptop') {
            margin: 0 $daily-deal-teaser_sections-gutter-tablet-up 0 0;
        }
    }

    &__description-list {
        ul {
            @include reset-list-style();
        }

        li {
            padding-left: $daily-deal-teaser_description-list-items-padding-left;
            margin: 0 0 0.5rem 0;
            position: relative;
            font-size: $daily-deal-teaser_description-list-item-font-size;

            &:before {
                content: '';
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                width: $daily-deal-teaser_description-list-item-icon-width;
                height: $daily-deal-teaser_description-list-item-icon-height;
                background: url($daily-deal-teaser_description-list-icon-path)
                    no-repeat center center / cover transparent;
            }

            &:nth-child(n
                    + #{$daily-deal-teaser_description-list-items-limit-mobile-and-tablet}) {
                display: none;
            }

            @include media('>=laptop') {
                &:nth-child(n
                        + #{$daily-deal-teaser_description-list-items-limit-desktop}) {
                    display: none;
                }
            }
        }
    }

    &__details-main {
        min-width: $daily-deal-teaser_main-max-width-desktop;
        flex-grow: 1;

        @include media('>=tablet') {
            margin-right: $daily-deal-teaser_sections-gutter-tablet-up;
        }
    }

    &__product-brand {
        margin: 0;
        color: $daily-deal-teaser_product-brand-color;
    }

    &__product-name {
        margin: $daily-deal-teaser_product-name-margin;
        font-size: $daily-deal-teaser_product-name-font-size;
    }

    &__product-link {
        color: $daily-deal-teaser_product-link-color;

        &,
        &:visited,
        &:hover {
            color: $daily-deal-teaser_product-link--hover-color;
            text-decoration: inherit;
        }
    }

    &__product-rating {
        margin: $daily-deal-teaser_product-rating-margin;
    }

    &__dd-detail {
        @include media('>=tablet') {
            margin-top: 0;
            padding: 0;
        }

        &--countdown {
            @include media('>=phoneLg') {
                width: $daily-deal-teaser_product-dd-counter-width-tablet-up;
                flex-grow: 1;
                margin-right: $daily-deal-teaser_product-dd-columns-gutter;
            }

            @include media('>=laptop') {
                align-self: flex-end;
            }
        }

        &--progress-bar {
            margin-bottom: $daily-deal-teaser_product-dd-columns-gutter;

            @include media('>=phoneLg') {
                width: 100%;
            }

            @include media('>=laptop') {
                margin-bottom: 0;
            }

            @include media('>=laptopLg') {
                align-self: flex-end;
            }
        }
    }

    &__addtocart {
        padding-top: $daily-deal-teaser_product-dd-columns-gutter;
        display: flex;
        justify-content: center;

        @include media('>=phoneLg') {
            align-items: flex-end;
        }

        @include media('>=tablet') {
            align-self: flex-end;
        }

        @include media('>=laptop') {
            width: $daily-deal-teaser_addtocart-wrapper-width-tablet-up;
            display: block;
            padding: 0;
        }

        @include media('>=laptopLg') {
            padding-left: $daily-deal-teaser_sections-gutter-tablet-up;
        }
    }

    &__dd-headline {
        margin: $daily-deal-teaser_product-dd-headline-margin;
        font-size: $daily-deal-teaser_product-dd-headline-font-size;
        font-weight: $daily-deal-teaser_product-dd-headline-font-weight;
        text-transform: $daily-deal-teaser_product-dd-headline-text-transform;
    }

    &__progress-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        margin: $daily-deal-teaser_product-dd-progress-bar-margin;
        padding: $daily-deal-teaser_product-dd-progress-bar-padding;
        background: $daily-deal-teaser_product-dd-progress-bar-background;
        border-radius: $daily-deal-teaser_product-dd-progress-bar-border-radius;

        &-content {
            width: 100%;
            height: $daily-deal-teaser_product-dd-progress-bar-height;
            background: $daily-deal-teaser_product-dd-progress-bar-content-background;
        }

        #{$root}__progress-bg {
            background-color: $daily-deal-teaser_product-dd-progress-bar-content-background;
            height: 100%;
        }

        #{$root}__progress {
            background-color: $daily-deal-teaser_product-dd-progress-bar-progress;
            height: 100%;
            border: 1px solid
                $daily-deal-teaser_product-dd-progress-bar-progress;
        }

        #{$root}__progress-possible {
            height: $daily-deal-teaser_product-dd-progress-bar-possible-height;
            border: $daily-deal-teaser_product-dd-progress-bar-possible-border;
            margin: $daily-deal-teaser_product-dd-progress-bar-possible-margin;
            border-radius: $daily-deal-teaser_product-dd-progress-bar-border-radius;
            background: $daily-deal-teaser_product-dd-progress-bar-possible-background;
        }
    }

    &__available {
        white-space: nowrap;
        padding-left: 1rem;
        font-size: $daily-deal-teaser_product-dd-available-font-size;
        font-weight: $daily-deal-teaser_product-dd-available-font-weight;
    }

    &__actions {
        padding: $daily-deal-teaser_sections-gutter-mobile 0;

        @include media('>=phoneLg') {
            display: flex;
            flex-wrap: wrap;
        }

        @include media('>=tablet') {
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            padding: $daily-deal-teaser_sections-gutter-tablet-up 0;
            flex-wrap: wrap;
        }

        @include media('>=laptop') {
            flex: 1;
            flex-direction: row;
            flex-wrap: wrap;
            width: auto;
            justify-content: center;
            align-items: flex-start;
            min-width: $daily-deal-teaser_actions-max-width-desktop;
            padding: 0;
        }
    }

    &__details-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.5rem;

        @include media('>=tablet') {
            justify-content: flex-start;
            padding-bottom: 0;
            flex-direction: column;
            width: $daily-deal-teaser_price-wrapper-width;
        }

        @include media('>=laptop') {
            flex-direction: row;
            height: $daily-deal-teaser_price-details-height-laptop;
            justify-content: flex-end;
            align-items: flex-start;
            align-self: flex-start;
        }
    }

    &__price {
        .price-box {
            .old-price .price {
                font-size: $daily-deal-teaser_price-old-font-size;
            }

            .special-price .price,
            .normal-price .price {
                font-size: $daily-deal-teaser_price-final-font-size;
            }
        }
    }

    &__discount {
        margin-left: 0.5rem;
        padding: 0 0 $daily-deal-teaser_sections-gutter-mobile;

        @if ($daily-deal-teaser_discount-badge-position-absolute) {
            position: absolute;
            top: 0;
            left: $daily-deal-teaser_sections-gutter-mobile;
            z-index: 10;

            @include media('>=tablet') {
                left: 0;
            }
        }
    }

    &__badge-wrapper {
        &:before {
            left: -#{$daily-deal-teaser_discount-badge-font-size / 3};
        }
    }

    &__badge {
        @include badge();

        font-size: $daily-deal-teaser_discount-badge-font-size;

        &--discount {
            @include badge_type('discount');

            #{$root}__badge-text {
                @include badge_span('discount');
            }
        }
    }

    &__badge-text {
        @include badge_span();
    }

    .tocart {
        margin-top: $daily-deal-teaser_addtocart-margin-top;
        min-width: $daily-deal-teaser_addtocart-min-width-mobile;
        @include button(
            $type: $daily-deal-teaser_addtocart-button-type,
            $icon_pos: $daily-deal-teaser_addtocart-button-icon-pos
        );

        span {
            @include button_span(
                $type: $daily-deal-teaser_addtocart-button-type,
                $icon_pos: $daily-deal-teaser_addtocart-button-icon-pos
            );

            @include media('>=laptop') {
                padding-right: 2em;
            }

            @include media('>=laptopLg') {
                padding-right: 4em;
            }
        }

        svg {
            @include button_icon(
                $type: $daily-deal-teaser_addtocart-button-type,
                $icon_pos: $daily-deal-teaser_addtocart-button-icon-pos
            );
        }

        @include media('>=phoneLg') {
            min-width: $daily-deal-teaser_addtocart-min-width;
        }

        @include media('>=tablet') {
            margin-top: 0;
        }

        @include media('>=laptop') {
            width: 100%;
            min-width: $daily-deal-teaser_addtocart-min-width;
        }
    }

    &--active {
        display: block;

        @include media('>=tablet') {
            display: flex;
            flex-wrap: wrap;
        }

        @include media('>=laptop') {
            flex-wrap: nowrap;
        }
    }

    &--expired {
        display: none;
    }
}
