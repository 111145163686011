/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-social-media-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  padding: 1.5rem 0 1.5rem;
  justify-content: center;
  align-items: center; }
  @media (min-width: 48em) {
    .cs-social-media-list {
      justify-content: flex-start; } }
  @media (min-width: 48em) {
    .cs-social-media-list {
      padding-bottom: 0;
      padding-top: 0; } }
  .cs-social-media-list__item {
    margin: 0 0.4em;
    padding: 0; }
  .cs-social-media-list__link {
    display: flex;
    position: relative;
    height: 3.5em;
    min-width: 4em;
    background: #212121;
    color: inherit;
    border-radius: 3px;
    transform: skew(0deg);
    transition: background-color 0.15s linear; }
    .cs-social-media-list__link:hover {
      background: #d21b37; }
  .cs-social-media-list__icon {
    position: absolute;
    fill: #fff;
    transform: translate3d(-55%, -55%, 0) skew(0deg);
    transition: fill 0.15s linear;
    top: 50%;
    left: 55%; }
    .cs-social-media-list__item--fb .cs-social-media-list__icon {
      width: 0.9em;
      height: 1.8em; }
    .cs-social-media-list__item--tw .cs-social-media-list__icon {
      width: 2.5em;
      height: 2em; }
    .cs-social-media-list__item--yt .cs-social-media-list__icon {
      width: 1.8em;
      height: 2.1em; }
    .cs-social-media-list__item--gp .cs-social-media-list__icon {
      width: 2em;
      height: 2em;
      margin-top: 0.1em; }
