/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-dropdown-switcher {
  white-space: nowrap;
  cursor: pointer;
  width: 100%; }
  @media (min-width: 64em) {
    .cs-dropdown-switcher .mage-dropdown-dialog {
      position: absolute;
      top: 100%;
      right: -1rem;
      left: 1rem; } }
  .cs-dropdown-switcher--currency .mage-dropdown-dialog {
    right: -1rem; }
  @media (min-width: 64em) {
    .cs-dropdown-switcher {
      position: relative;
      display: inline-flex;
      align-items: center;
      flex-shrink: 0; }
      .cs-dropdown-switcher:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 1.3rem;
        content: '';
        border-left: 0.2rem solid #fff;
        transform: skew(-18deg);
        display: block; } }
  @media (min-width: 64em) {
    .cs-dropdown-switcher--stores:before {
      display: none; } }
  .cs-dropdown-switcher__option-icon {
    min-width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.6rem;
    margin-bottom: 0.2rem;
    display: inline-block; }
    @media (min-width: 64em) {
      .cs-dropdown-switcher--language .cs-dropdown-switcher__option-icon {
        width: 1.5rem; } }
  @media (min-width: 64em) {
    .cs-dropdown-switcher .switcher-options {
      display: flex; } }
  .cs-dropdown-switcher__trigger {
    padding: 2.4rem 2rem;
    font-size: 1.4rem;
    font-weight: 700;
    border-bottom: 1px #dcdcdc solid;
    position: relative;
    position: relative; }
    .cs-dropdown-switcher__trigger:before, .cs-dropdown-switcher__trigger:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 0.7rem;
      height: 0.1rem;
      left: auto;
      background-color: #E5464B;
      transition: transform 0.15s linear, right 0.15s linear, background-color 0.15s linear;
      backface-visibility: hidden; }
    .cs-dropdown-switcher__trigger:before {
      transform: rotate(45deg) translate3d(0, 0, 0);
      right: 3rem; }
    .cs-dropdown-switcher__trigger:after {
      transform: rotate(-45deg) translate3d(0, 0, 0);
      right: 2.5rem; }
    .cs-dropdown-switcher__trigger.active:before {
      transform: rotate(-45deg); }
    .cs-dropdown-switcher__trigger.active:after {
      transform: rotate(45deg); }
    @media (min-width: 64em) {
      .cs-dropdown-switcher__trigger {
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-left: 2rem;
        font-size: 1.1rem;
        font-weight: 400;
        padding: 0 1.5rem 0 0;
        border-bottom: none;
        position: relative; }
        .cs-dropdown-switcher__trigger:before, .cs-dropdown-switcher__trigger:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 0.7rem;
          height: 0.1rem;
          left: auto;
          background-color: #000;
          transition: transform 0.15s linear, right 0.15s linear, background-color 0.15s linear;
          backface-visibility: hidden; }
        .cs-dropdown-switcher__trigger:before {
          transform: rotate(45deg) translate3d(0, 0, 0);
          right: 0.5rem; }
        .cs-dropdown-switcher__trigger:after {
          transform: rotate(-45deg) translate3d(0, 0, 0);
          right: 0; }
        .cs-dropdown-switcher__trigger.active:before {
          transform: rotate(-45deg); }
        .cs-dropdown-switcher__trigger.active:after {
          transform: rotate(45deg); } }
    .cs-dropdown-switcher__trigger.active {
      background: transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: none;
      position: relative;
      width: 100%; }
      @media (max-width: 63.99em) {
        .cs-dropdown-switcher__trigger.active {
          padding: 2.4rem 2rem;
          border-style: none; } }
    .cs-dropdown-switcher__trigger-span {
      display: flex;
      align-items: center; }
  .cs-dropdown-switcher__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    list-style: none;
    font-size: 1.1rem;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-color: #f0f0f0;
    border-style: solid;
    border-width: 0 1px 1px;
    width: 100%; }
    @media (min-width: 64em) {
      .cs-dropdown-switcher__list {
        background: #fff;
        min-width: 100%; } }
    .cs-dropdown-switcher__list-item {
      margin: 0;
      white-space: nowrap; }
    .cs-dropdown-switcher__list-item-link {
      width: 100%;
      color: #212121 !important;
      display: flex;
      align-items: center; }
      .cs-dropdown-switcher__list-item-link:hover {
        background-color: #f0f0f0;
        text-decoration: none; }
      @media (max-width: 63.99em) {
        .cs-dropdown-switcher__list-item-link {
          padding: 1.4rem 2rem;
          font-size: 1.4rem; } }
      @media (min-width: 64em) {
        .cs-dropdown-switcher__list-item-link {
          padding: 0.6rem 1.8rem;
          font-size: 1.1rem; } }
