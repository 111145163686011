.catalog-category-view {
  .configurable,
  .question-button {
    display: none;
  }
}

.catalog-product-view {
  .configurable {
    display: none;
  }
}