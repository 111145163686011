.customer-account-create {
  .field .label {
    margin-bottom: 0;
  }


}

.additional-abplanalp-customer-attributes {
    .step-title {
      display: none;
    }
}
