@import 'config/variables';
@import 'utils/component-spacing';
@import 'components/button/mixin';

$button-fallback_type: $button_default-type !default;
$button-fallback_icon-pos: $button_default-icon-pos !default;

$button-component_type: $button_default-type !default;
$button-component_icon-pos: $button_default-icon-pos !default;
$button-component_horizontal-position: 50% !default;
$button-component_horizontal-translation: translateX(-50%) !default;

.#{$ns}button {
    @include button(
        $type: $button-fallback_type,
        $icon_pos: $button-fallback_icon-pos
    );

    &__span {
        @include button_span(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-fallback_type,
            $icon_pos: $button-fallback_icon-pos
        );
    }
}

.#{$ns}button-component {
    @include button(
        $type: $button-component_type,
        $icon_pos: $button-component_icon-pos
    );
    @include component-spacing();

    position: relative;
    left: $button-component_horizontal-position;
    transform: $button-component_horizontal-translation;

    &__span {
        @include button_span(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__icon {
        @include button_icon(
            $type: $button-component_type,
            $icon_pos: $button-component_icon-pos
        );
    }

    &__amount {
        &:before {
            content: '(';
        }
        &:after {
            content: ')';
        }
    }
}
