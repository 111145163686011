/* stylelint-disable block-no-empty */
@mixin button-hook($type, $icon_pos) {
}

@mixin button_span-hook($type, $icon_pos) {
}

@mixin button_icon-hook($type, $icon_pos) {
}
/* stylelint-enable */
