@import 'config/variables';
@import 'config/colors';
@import 'vendors/include-media';
@import 'vendors/include-media';
@import 'components/badge/mixin';

$additional-attributes_th-font-weight: 700 !default;
$additional-attributes_tr-border: 1px solid $color_border-500 !default;
$additional-attributes_cell-padding: 1em 1.5em 1em 0 !important !default;
$additional-attributes_table-font-size: 1.6rem !important;



$additional-attributes_badge-margin: 0 0 0 0.9em !default;
$additional-attributes_top-align: -0.01em !default;


.catalog-product-view {

  tbody {
      > tr {
          border: $additional-attributes_tr-border;
          border-left: 0;
          border-right: 0;
          border-collapse: collapse;

          & + tr {
              // For some reason border-collapse doesn't work well on mobile FireFox.
              border-top: 0;
          }

          > th {
              font-weight: $additional-attributes_th-font-weight;
              padding: $additional-attributes_cell-padding;
              color: $color_cerulean-blue;
              font-size: $additional-attributes_table-font-size;
          }

          > td {
              padding: $additional-attributes_cell-padding;
              font-weight: $additional-attributes_th-font-weight !important;
              font-size: $additional-attributes_table-font-size;
          }
      }
  }

.price-box {
  margin-bottom: 2rem;
  .price {
    margin-left: 5px;
  }
}

  .brand-link-image  {
    margin: -4rem 2rem;
    img {
      max-width: 100px !important;
    }

  }


  .cs-container--headline {
    margin-top: 0rem;
    }

  h1 {
    display: block;
    text-align: left;

    &:before, &:after {
      display: none;
    }
  }
}
