@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}logo {
    height: 100%;
    // animation: headShake; /* referring directly to the animation's @keyframe declaration */
    // animation-duration: 0.5s; /* don't forget to set a duration! */
    // animation-delay: 10s;
    // animation-iteration-count: infinite;

    &__image {
        height: 100%; // Fixes height: auto bug on chrome.

    }
}
