/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.brand-link-image img {
  max-width: 80px !important; }

.cms-home .brand-attributes-redingo {
  display: flex;
  justify-content: space-between;
  min-width: 153px;
  margin-top: -50px; }

.brands-index-all #maincontent {
  background: #f6f6f6; }

.brands-index-all .cs-brands-index,
.brands-index-all .cs-container--headline,
.brands-index-all .cs-container--usps,
.brands-index-all .cs-image-teaser__content {
  background: #f6f6f6 !important; }

.brands-index-all .cs-brands-index__icon {
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1); }

.brands-index-all .cs-brands-index__img {
  max-width: 80%; }
  @media (max-width: 64em) {
    .brands-index-all .cs-brands-index__img {
      max-width: 90%; } }
  @media (max-width: 30em) {
    .brands-index-all .cs-brands-index__img {
      max-width: 95%; } }

.cs-authorization-link__name {
  margin-right: 10px; }
