@import 'config/variables';
@import 'config/breakpoints';
@import 'include-media/dist/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/typography/mixin';

.product-simple {
  height: 250px;
  //height: 170px;
  padding: 0.4rem;
  max-width: 300px;
  display: flex;
  flex-direction: column;

  .bottom-informations {
    display: flex;
    justify-content: center;
  }

  .product-card-container {
    width: 100%;
    display: block;
    height: 100%;
    min-height: 190px;
    background-repeat: round;
    position: relative;
  }

  .tocart {
    padding-left: 20px;
  }

  .cs-addtocart__button {
    text-align: left;
  }

  .actions-primary {
    width: 217px;
    padding: 5px 0;
    font-size: 24px;
  }

  .title {
    text-align: center;
    width: 100%;
    display: block;
    padding: 5px 0;
    font-size: 12px !important; 
  }
}
#ylMachinesLink,
#ylRecommendedLink{


  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include media('>=tablet') {
    justify-content: space-between;
  }
  &:before{
    display: none;
  }
  &:after{
      display: none;
    }
}
.cms-home{
  .col-md-4,
  .col-lg-3.d-lg-block.d-md-none.col-12{
    //max-width: 300px;
    margin-bottom: 6rem;
  //  max-width: 100%;

  //  max-width: 50%;
  }
}
