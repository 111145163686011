@import 'config/variables';
@import 'vendors/include-media';

@import 'components/field/mixin';
@import 'components/button/mixin';

$input_padding: 0.715em 1em !default;
$input_border: 1px solid $color_alto !default;
$input_border-radius: $border-radius_base !default;

$newsletter_margin: 0 !default;
$newsletter_padding: 0 1.5rem !default;

$newsletter_form-max-width: 34rem !default;
$newsletter_form-height: 4rem !default;

$newsletter_button-type: $button_default-type !default;
$newsletter_button-icon-pos: 'only-icon' !default;

$newsletter_button-icon-width: 0.8em !default;
$newsletter_button-icon-height: 0.8em !default;

.#{$ns}newsletter {
    margin: $newsletter_margin;
    width: 100%;

    @include media('<tablet') {
        padding: $newsletter_padding;
    }

    &__form {
        position: relative;
        display: flex;
        overflow: hidden;
        flex-direction: column;

        @include media('>=tablet') {
            max-width: $newsletter_form-max-width;
        }

        @include media('>=laptop') {
            max-width: 100%;
        }
    }

    &__field {
        flex-basis: 100%;
    }

    &__input {
        margin-top: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &__actions {
        display: flex;
        align-items: stretch;
        height: $newsletter_form-height;
        position: absolute;
        right: 0;
    }

    &__button {
        @include button(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );

        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &__button-icon {
        @include button-icon(
            $type: $newsletter_button-type,
            $icon_pos: $newsletter_button-icon-pos
        );

        width: $newsletter_button-icon-width;
        height: $newsletter_button-icon-height;
        margin-right: 0;
    }
}

.zgody-newsletter {
  display: block;
  margin: 1rem 0 1rem 0;

  .checkbox-custom {
    margin: 0 0 1rem 0;
  }
}
