.category-edukacja,
.category-branza,
.category-wiedza,
.category-firma,
.category-leasing,
.category-narzedzia-e-sklep,
.category-obrabiarki

 {

.page-title {

  display: none;
}

}
.page-with-filter.page-products.catalog-category-view {
  .cs-product-tile__stock.cs-product-tile__stock--in {
    display: none;
  }
}


.category-o-abplanalp {
  #maincontent {
  .cs-page-category__cc-top {
    div {
      &:nth-child(4) {
        .cs-image-teaser__aspect-ratio {
          padding-top: 39%!important;
        }
      }
      &:nth-child(5) {
        .cs-image-teaser__aspect-ratio {
          padding-top: 39%!important;
        }
      }
      &:nth-child(6) {
        .cs-image-teaser__aspect-ratio {
          padding-top: 39%!important;
        }
      }
      &:nth-child(8) {
        img {
          object-fit: contain;
        }
      }
    }
    .cs-image-teaser__slide {
      padding: 0px;
    }
  }
  .cs-image-teaser__slide-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cs-image-teaser__figure {
    img {
      object-fit: cover;
      width: 100%;
      height: 280px;
    }
  }
}
}


.category-holding-abplanalp,
.category-o-abplanalp,
.category-do-pobrania,
.category-abplanalp-finansowanie,
.category-serwis-i-naprawa,
.category-pomiary-laserem,
.category-automatyzacja,
.category-program-htec,
.category-abplanalp-w-edukacji,
.page-layout-better-cms {
  .page-title-wrapper.cs-page-title.cs-page-title--page-category {
    display: none;
  }
}

.checkout-index-index {
  .cs-headline__title {
    margin: 0 auto;
    span {
      margin: 0 auto;
    }

    &:before, &:after {
      display: none;
    }
  }
}

.prices-tier {
  font-size: 16px;
  border: #e5464b 2px dashed;
  padding: 10px 10px 0px 15px;
  .notranslate {
    display: inline;
    margin-left: 5px;
  }
}
