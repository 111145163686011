/* stylelint-disable block-no-empty */
@mixin headline-hook() {
}

@mixin headline-title-hook() {
}

@mixin headline-subtitle-hook() {
}
/* stylelint-enable */
