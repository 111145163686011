@import 'config/variables';
@import 'utils/component-spacing';

$separator_color: $color_border-500 !default;
$separator_style: 1px solid $separator_color !default;

.#{$ns}separator {
    position: relative;
    clear: both;
    border: none;
    border-top: $separator_style;
    background: transparent;
    @include component-spacing();
}
