/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.catalog-product-view tbody > tr {
  border: 1px solid #dcdcdc;
  border-left: 0;
  border-right: 0;
  border-collapse: collapse; }
  .catalog-product-view tbody > tr + tr {
    border-top: 0; }
  .catalog-product-view tbody > tr > th {
    font-weight: 700;
    padding: 1em 1.5em 1em 0 !important;
    color: #E5464B;
    font-size: 1.6rem !important; }
  .catalog-product-view tbody > tr > td {
    padding: 1em 1.5em 1em 0 !important;
    font-weight: 700 !important;
    font-size: 1.6rem !important; }

.catalog-product-view .price-box {
  margin-bottom: 2rem; }
  .catalog-product-view .price-box .price {
    margin-left: 5px; }

.catalog-product-view .brand-link-image {
  margin: -4rem 2rem; }
  .catalog-product-view .brand-link-image img {
    max-width: 100px !important; }

.catalog-product-view .cs-container--headline {
  margin-top: 0rem; }

.catalog-product-view h1 {
  display: block;
  text-align: left; }
  .catalog-product-view h1:before, .catalog-product-view h1:after {
    display: none; }
