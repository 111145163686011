@import 'config/variables';
@import 'vendors/include-media';

$messages_success-background-color: $color_success-200 !default;
$messages_info-background-color: $color_warning-200 !default;
$messages_notice-background-color: $color_warning-200 !default;
$messages_error-background-color: $color_error-200 !default;

$messages_success-border: none !default;
$messages_info-border: none !default;
$messages_notice-border: none !default;
$messages_error-border: none !default;

$messages_border-radius: $border-radius_base !default;
$messages_padding: 1.2rem 1rem 1rem !default;
$messages_text-color: $color_text-500 !default;
$message_icon-width: 2rem !default;
$message_icon-height: $message_icon-width !default;

.messages {
    max-width: $page_max-width;
    margin: 0 auto;
}

.#{$ns}messages__message,
.message {
    margin: 1rem 0;
    padding: $messages_padding;
    border-radius: $messages_border-radius;
    background-repeat: no-repeat;
    background-position: 1.2rem center;
    background-size: $message_icon-width $message_icon-height;
    padding-left: 4.3rem;
}

.#{$ns}messages__message--success,
.message.success {
    background-color: $messages_success-background-color;
    background-image: url('../images/icons/messages/icon-success.svg');
    border: $messages_success-border;
}

.#{$ns}messages__message--notice,
.message.warning {
    background-color: $messages_notice-background-color;
    background-image: url('../images/icons/messages/icon-warning.svg');
    border: $messages_notice-border;
}

.#{$ns}messages__message--info,
.message.info,
.message.notice {
    background-color: $messages_info-background-color;
    background-image: url('../images/icons/messages/icon-info.svg');
    border: $messages_info-border;
}

.#{$ns}messages__message--error,
.message.error {
    background-color: $messages_error-background-color;
    background-image: url('../images/icons/messages/icon-error.svg');
    border: $messages_error-border;
}

.#{$ns}messages__text,
.message span {
    color: $messages_text-color;
    font-size: 1.4rem;
}

.#{$ns}messages {
    $root: &;

    &--space-top {
        margin-top: 1.5rem;
    }
}
