/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
.cs-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }
