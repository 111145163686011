@import 'config/variables';
@import 'vendors/include-media';

.#{$ns}display-controller {
    &--hidden-mobile {
        @include media('<tablet') {
            display: none;
        }
    }

    &--hidden-desktop {
        @include media('>=tablet') {
            display: none;
        }
    }
}
