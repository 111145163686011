.lazyload,
.lazyloading,
.swiper-lazy {
    // Added to prevent lazySizes from delaying images load.
    opacity: 0.001;
}

.lazyloaded,
.swiper-lazy-loaded {
    opacity: 1;
    transition: opacity 0.2s;
}
