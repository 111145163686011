@import 'config/variables';
@import 'components/typography/mixin';

h1 {
    @include headline($level: $font-size_headline-1);
}

h2 {
    @include headline($level: $font-size_headline-2);
}

h3 {
    @include headline($level: $font-size_headline-3);
}

h4 {
    @include headline($level: $font-size_headline-4);
}

h5 {
    @include headline($level: $font-size_headline-5);
}

h6 {
    @include headline($level: $font-size_headline-6);
}

a {
    @include link();
}

ul,
ol {
    @include list();

    > li {
        @include list-item();
    }
}
