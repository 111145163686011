@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/component-spacing';

/**
 * Gap between columns (all CSS units allowed) [Example: 1.5rem]
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap
 */
$paragraph_column-gap: 6rem !default;
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/column-rule
 */
$paragraph_column-rule: 1px solid $color_border-500 !default;

.#{$ns}paragraph {
    column-gap: $paragraph_column-gap;
    column-rule: $paragraph_column-rule;
    @include component-spacing();

    img {
        height: auto;
    }

    @include media('>=tablet') {
        &--cols-2,
        &--cols-3,
        &--cols-4 {
            column-count: 2;

            li {
                width: 100%;
            }

            img {
                display: block;
                max-width: 100%;
            }
        }
    }

    @include media('>=laptop') {
        &--cols-3,
        &--cols-4 {
            column-count: 3;
        }
    }

    @include media('>=laptopLg') {
        &--cols-4 {
            column-count: 4;
        }
    }

    &--mode-optimal {
        max-width: 40em;
        margin-left: auto;
        margin-right: auto;
        column-count: initial;
    }
}
