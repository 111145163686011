/* stylelint-disable block-no-empty */
@mixin headline_hook($level) {
}

@mixin link_hook() {
}

@mixin list_hook() {
}

@mixin list-item_hook() {
}
/* stylelint-enable */
