/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.brands-abplanalp {
  width: 46% !important;
  margin: 0 4% 3rem 0; }
  @media (max-width: 48em) {
    .brands-abplanalp {
      width: 100% !important;
      margin: 0 0 3rem 0; } }
  .brands-abplanalp.left {
    border-right: 1px solid #dcdcdc; }
    @media (max-width: 48em) {
      .brands-abplanalp.left {
        border-right: unset; } }

.brands_opis_lead {
  font-size: 16px;
  text-align: justify; }
