/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cms-home .product-card-footer .price, .cms-home-ru .product-card-footer .price {
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  min-height: 30px; }

.cms-home #ylMachines .col-md-4, .cms-home-ru #ylMachines .col-md-4 {
  display: flex;
  justify-content: center; }

.cms-home .columns .column.main, .cms-home-ru .columns .column.main {
  padding: 0rem 0 0; }

.cms-home .cs-container--headline, .cms-home-ru .cs-container--headline {
  margin: 0rem 0 0rem; }
  @media (min-width: 90em) {
    .cms-home .cs-container--headline, .cms-home-ru .cs-container--headline {
      margin: 2rem 0 1rem; } }
  @media (min-width: 120em) {
    .cms-home .cs-container--headline, .cms-home-ru .cs-container--headline {
      margin: 4rem 0 1rem; } }
  .cms-home .cs-container--headline .cs-headline, .cms-home-ru .cs-container--headline .cs-headline {
    margin: 0 0 0rem; }
    @media (min-width: 120em) {
      .cms-home .cs-container--headline .cs-headline, .cms-home-ru .cs-container--headline .cs-headline {
        margin: 0 0 2rem; } }

.cms-home .cs-products-grid__grid .brand-attributes-redingo, .cms-home-ru .cs-products-grid__grid .brand-attributes-redingo {
  display: none; }

.cms-home .cs-products-grid__grid .cs-product-tile--grid .cs-product-tile__content, .cms-home-ru .cs-products-grid__grid .cs-product-tile--grid .cs-product-tile__content {
  font-weight: 700; }

.cms-home .cs-products-grid__grid .cs-products-grid, .cms-home-ru .cs-products-grid__grid .cs-products-grid {
  margin: 0rem !important; }

.cms-home .cs-products-grid__grid .cs-links-block-addto__link-label, .cms-home .cs-products-grid__grid .cs-product-tile__stock, .cms-home .cs-products-grid__grid .configurable, .cms-home .cs-products-grid__grid .question-button, .cms-home .cs-products-grid__grid .cs-links-block-addto__item--wishlist, .cms-home .cs-products-grid__grid .cs-product-tile__swatches, .cms-home .cs-products-grid__grid .cs-product-tile__price, .cms-home-ru .cs-products-grid__grid .cs-links-block-addto__link-label, .cms-home-ru .cs-products-grid__grid .cs-product-tile__stock, .cms-home-ru .cs-products-grid__grid .configurable, .cms-home-ru .cs-products-grid__grid .question-button, .cms-home-ru .cs-products-grid__grid .cs-links-block-addto__item--wishlist, .cms-home-ru .cs-products-grid__grid .cs-product-tile__swatches, .cms-home-ru .cs-products-grid__grid .cs-product-tile__price {
  display: none; }

.cs-image-teaser--items-in-row-1 .cs-image-teaser__content {
  margin-top: 100%; }

.cs-image-teaser--items-in-row-1 .cs-image-teaser__text-content .cs-image-teaser__slogan {
  font-size: 36px;
  animation: heartBeat;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
  /* don't forget to set a duration! */
  animation-delay: 3s;
  animation-iteration-count: infinite; }

.cs-image-teaser--items-in-row-1 .cs-image-teaser__text-content .cs-image-teaser__description {
  font-size: 26px; }
