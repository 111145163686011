@mixin link(
    $link,
    $action,
    $visited: $action,
    $hover: $action,
    $active: $action,
    $size: false
) {
    & {
        @if ($size) {
            font-size: $size;
        }
        color: $link;

        &:visited {
            color: $visited;
        }

        &:hover {
            color: $hover;
        }

        &:active,
        &:focus {
            color: $active;
        }
    }
}
