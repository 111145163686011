@import 'config/variables';
@import 'config/breakpoints';
@import 'include-media/dist/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/typography/mixin';
@import 'vendors/include-media';

.page-layout-better-cms {
    .leasing-abplanalp-steps {
      width: 85%;
      margin: 0 auto;
      .col-md-4 {
        @include media('<=phoneLg') {
            margin: 0 0 25px 0;
        }
        text-align: center;
        &:nth-child(1),
        &:nth-child(2) {
          .line {
            &:after {
              content: "";
              background: #333;
              height: 2px;
              width: 30px;
              top: 39px;
              position: absolute;
              right: 5px;
              @include media('<=phoneLg') {
                  top: 30px;
              }
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          .line {
            &:before {
              content: "";
              background: #333;
              height: 2px;
              width: 30px;
              top: 39px;
              position: absolute;
              left: 5px;
              @include media('<=phoneLg') {
                  top: 30px;
              }
            }
          }
        }
        .line {
          h2 {
            color: #fff;
            background: #333;
            padding: 15px 0;
            clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
            margin: 10px 55px;
          }
        }

      }
    }
      .col-md-4 {

      }
  .text-size {
    font-size: 1.8rem;
  }
  .text-size.center {
    text-align: center;
    padding: 20px 0;
  }
  .first-bullet {
    h2.text {
      width:40%;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      margin: 0;
      @include media('<=tablet') {
        justify-content: flex-start;
    }
    @include media('<=phoneLg') {
      width: 50%;
      text-align: center;
  }
    }
    .dot-container {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media('<=phoneLg') {
        justify-content: flex-end;

      }
      .dot.red-dot {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color_torea-bay;
        color: $color_white;
    }
    .dot.green-dot {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color_apple-dark;
      color: $color_white;
  }
    }
    .text-green-dot {
      display: flex;
      justify-content: center;
      margin-left: 60%;
      margin-top: -110px;
      position: absolute;
      @include media('<=phoneLg') {
          margin-left: 0 !important;
          width: 50%;
          text-align: center;
          margin-top: -120px;
      }
    }
  }
}





  .baner-leasing {
    img {
      width: 100%;
    }
  }

  .leasing-abplanalp {
    padding: 4rem 0;
    background: $color_black;
    h2 {
      text-transform: unset;
      text-align: center;
      color: $color_white;
    }
  }

  .leasing-abplanalp-green {
     p {
       font-size: 1.8rem;
       text-align: justify;
     }
     .col-md-4 {
    padding: 4rem;
    @include media('<=desktop') {
      padding: 2rem;
  }
    @include media('<=phoneLg') {
      padding: 2rem 0;
  }
}



  h2 {
      color: $color_apple-dark;
      text-align: center;
  }
}

.dot-pdng {
  margin-top: 25px;
  @include media('<=phoneLg') {
    margin-top: 0;
  }
}
