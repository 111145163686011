/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-authorization-link {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 0.9rem;
  padding: 0 0 0 0.3rem; }
  @media (min-width: 64em) {
    .cs-authorization-link {
      padding: 0 0 0 1.3rem;
      margin-left: 1.7rem; } }
  .cs-authorization-link:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    border-left: 0.2rem solid #fff;
    transform: skew(-18deg); }
  .cs-authorization-link__link {
    display: flex;
    align-items: center; }
    .cs-authorization-link__link {
      color: #E5464B; }
      .cs-authorization-link__link:visited {
        color: #d21b37; }
      .cs-authorization-link__link:hover {
        color: #d21b37; }
      .cs-authorization-link__link:active, .cs-authorization-link__link:focus {
        color: #d21b37; }
    .cs-authorization-link__link:hover .cs-authorization-link__icon path {
      fill: #888; }
  .cs-authorization-link__icon {
    width: 1.8rem;
    height: 1rem;
    padding-right: 0.5rem; }
    .cs-authorization-link__icon path {
      fill: #888; }
