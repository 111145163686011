/* stylelint-disable block-no-empty */

@mixin bullet-pagination-list-hook() {
}

@mixin bullet-pagination-item-hook() {
}

@mixin bullet-pagination-item--active-hook() {
}

@mixin bullet-pagination-button-hook() {
}
