/**
 * USPs component customization.
 * @see /MageSuite_ContentConstructorFrontend for layout, templates and images.
 * USPs component is based on Image Teaser.
 */
@import 'config/variables';
@import 'vendors/include-media';

$usps_slides-per-view: (
    phone: 1,
    phoneLg: 2,
    tablet: 3,
    laptop: 6,
) !default;
$usps_slide-margin: 0 !default;
$usps_max-image-width: 18% !default;
$usps_max-image-height: 6.5rem !default;
$usps_image-margin: 0 auto 2rem !default;
$usps_show-carousel-arrows: false !default;

.#{$ns}image-teaser {
    $root: &;

    &--usps {
        #{$root}__slides {
            align-items: flex-start;
        }

        #{$root}__slide {
            margin: $usps_slide-margin;
        }

        &:not(#{$root}--slider) {
            #{$root}__slides {
                flex-wrap: nowrap;
            }
        }

        #{$root}__image {
            max-width: $usps_max-image-width;
            max-height: $usps_max-image-height;
            margin: $usps_image-margin;

            .ie11 & {
                height: 100%;
            }
        }

        #{$root}__text {
            display: block;
        }

        @if $usps_show-carousel-arrows == false {
            #{$root}__nav {
                display: none !important;
            }
        }
    }
}
