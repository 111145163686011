/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-offcanvas-navigation {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff; }
  .cs-offcanvas-navigation__list {
    position: relative;
    z-index: 1;
    width: 100%;
    min-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100%;
    -webkit-overflow-scrolling: touch; }
    .cs-offcanvas-navigation__list--current {
      overflow-y: auto; }
    .cs-offcanvas-navigation__list .cs-offcanvas-navigation__list {
      position: absolute;
      left: 105%;
      height: 100%;
      top: 0;
      transition: transform 0.3s; }
      .cs-offcanvas-navigation__list .cs-offcanvas-navigation__list--active {
        transform: translateX(-105%);
        transform: translate3d(-105%, 0, 0); }
  .cs-offcanvas-navigation__item {
    width: 100%;
    display: flex;
    overflow: hidden;
    margin: 0; }
    .cs-offcanvas-navigation__item + .cs-offcanvas-navigation__item {
      border-top: 1px #dcdcdc solid; }
    .cs-offcanvas-navigation__item:last-of-type {
      border-bottom: 1px #dcdcdc solid; }
    .cs-offcanvas-navigation__item--with-icon .cs-offcanvas-navigation__text {
      position: relative;
      padding-left: 2rem; }
  .cs-offcanvas-navigation__link {
    min-width: 100%;
    width: 100%;
    display: flex;
    padding: 2.4rem 2rem;
    font-size: 1.4rem;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    position: relative; }
    .cs-offcanvas-navigation__link, .cs-offcanvas-navigation__link:visited {
      color: #212121; }
    .cs-offcanvas-navigation__link:hover {
      color: #212121; }
    .cs-offcanvas-navigation__link--sign-in, .cs-offcanvas-navigation__link--sign-out {
      padding-right: 5rem; }
      .cs-offcanvas-navigation__link--sign-in, .cs-offcanvas-navigation__link--sign-in:visited, .cs-offcanvas-navigation__link--sign-out, .cs-offcanvas-navigation__link--sign-out:visited {
        color: #E5464B; }
      .cs-offcanvas-navigation__link--sign-in:hover, .cs-offcanvas-navigation__link--sign-out:hover {
        color: #E5464B; }
        .cs-offcanvas-navigation__link--sign-in:hover .cs-offcanvas-navigation__icon path, .cs-offcanvas-navigation__link--sign-out:hover .cs-offcanvas-navigation__icon path {
          fill: #E5464B; }
      .cs-offcanvas-navigation__link--sign-in .cs-offcanvas-navigation__icon, .cs-offcanvas-navigation__link--sign-out .cs-offcanvas-navigation__icon {
        width: 2.3rem;
        height: 2.7rem;
        right: 2rem; }
        .cs-offcanvas-navigation__link--sign-in .cs-offcanvas-navigation__icon path, .cs-offcanvas-navigation__link--sign-out .cs-offcanvas-navigation__icon path {
          fill: #E5464B; }
    .cs-offcanvas-navigation__link--parent .cs-offcanvas-navigation__icon, .cs-offcanvas-navigation__link--return .cs-offcanvas-navigation__icon {
      width: 0.7rem;
      height: 0.7rem; }
    .cs-offcanvas-navigation__link--parent {
      padding-right: 5rem; }
      .cs-offcanvas-navigation__link--parent .cs-offcanvas-navigation__icon {
        right: 2rem; }
    .cs-offcanvas-navigation__link--return {
      padding-left: 5rem; }
      .cs-offcanvas-navigation__link--return .cs-offcanvas-navigation__icon {
        left: 2rem; }
    .cs-offcanvas-navigation__link--next {
      position: relative; }
      .cs-offcanvas-navigation__link--next:before, .cs-offcanvas-navigation__link--next:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 0.7rem;
        height: 0.1rem;
        left: auto;
        background-color: #E5464B;
        backface-visibility: hidden; }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:before, .cs-offcanvas-navigation__link--next[aria-expanded="true"]:after {
        background-color: #888; }
      .cs-offcanvas-navigation__link--next:before {
        transform: rotate(135deg) translate3d(50%, 0, 0);
        right: 2rem; }
      .cs-offcanvas-navigation__link--next:after {
        right: 2rem;
        transform: rotate(-135deg) translate3d(50%, 0, 0); }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:before {
        transform: rotate(225deg) translate3d(0, 0, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--next[aria-expanded="true"]:after {
        transform: rotate(-45deg) translate3d(0, 0, 0);
        right: 2rem; }
    .cs-offcanvas-navigation__link--prev {
      position: relative; }
      .cs-offcanvas-navigation__link--prev:before, .cs-offcanvas-navigation__link--prev:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 0.7rem;
        height: 0.1rem;
        left: auto;
        background-color: #E5464B;
        backface-visibility: hidden; }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:before, .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:after {
        background-color: #888; }
      .cs-offcanvas-navigation__link--prev:before {
        transform: rotate(45deg) translate3d(50%, -50%, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--prev:after {
        right: 0;
        transform: rotate(-45deg) translate3d(50%, 50%, 0); }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:before {
        transform: rotate(-45deg) translate3d(0, 0, 0);
        right: auto; }
      .cs-offcanvas-navigation__link--prev[aria-expanded="true"]:after {
        transform: rotate(-135deg) translate3d(0, 0, 0);
        right: 0; }
      .cs-offcanvas-navigation__link--prev:before, .cs-offcanvas-navigation__link--prev:after {
        left: 2rem; }
  .cs-offcanvas-navigation .active > .cs-offcanvas-navigation__link {
    color: #E5464B; }
  .cs-offcanvas-navigation__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .cs-offcanvas-navigation__icon path {
      fill: #E5464B; }
  .cs-offcanvas-navigation__category-icon-wrapper {
    position: absolute;
    width: 2rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .cs-offcanvas-navigation__category-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    max-height: 2rem; }
  .cs-offcanvas-navigation__product-qty {
    font-weight: 400;
    color: #696969; }
    .cs-offcanvas-navigation__product-qty:before {
      content: '('; }
    .cs-offcanvas-navigation__product-qty:after {
      content: ')'; }
