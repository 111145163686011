@import 'config/variables';
@import 'components/button/mixin';
@import 'vendors/include-media';


.amlocator-store-desc .amlocator-schedule-container .amlocator-today:before {
  content: "\f017" ;
  font-family: $font_family-icon;
  font-weight: 600;
  color: $color_mineshaft;
  margin: 0 30px 0 0 !important;
  font-size: 16px;
  background: unset;
  line-height: 0.8;
}


.amlocator-store-desc .-active .amlocator-schedule-container .amlocator-today:before {
  content: "\f017" ;
  font-family: $font_family-icon;
  font-weight: 600;
  color: white !important;
  margin: 0 30px 0 0 !important;
  font-size: 16px;
  background: unset;
  line-height: 0.8;
}

.amlocator-store-desc .amlocator-title .amlocator-link {
    color: $color_mineshaft;
    font-weight: 500;
}

.amlocator-store-desc.-active .amlocator-today {
    color: #fff;
    background: #E5464B;

    &::before {
      content: "\f017" ;
      font-family: $font_family-icon;
      font-weight: 600;
      color: white !important;
      margin: 0 30px 0 0 !important;
      font-size: 16px;
      background: unset;
      line-height: 0.8;
    }


    &.amlocator-arrow:before, .amlocator-arrow:after {
        content: "";
        position: absolute;
        top: 3px;
        display: inline-block;
        width: 9px;
        height: 2px;
        background-color: #ffffff;
        transition: all .2s ease;
    }

    &.amlocator-arrow:before, .amlocator-arrow:before {
    content: "";
    position: absolute;
    top: 3px;
    display: inline-block;
    width: 9px;
    height: 2px;
    background-color: #ffffff;
    transition: all .2s ease;
    }
}




.amlocator-main-container .amlocator-wrapper .amlocator-schedule-container strong {
    margin-left: 6px;
}

.amlocator-row {
  margin: 2rem 0;
}


.amlocator-schedule-table .amlocator-cell .-day, .-time {
    line-height: 0;
}

.amlocator-location-view, .amlocator-index-index {
  h1 {
    margin-top: 2rem;
  }
  .columns .column.main {
      padding: 1rem 0 0;
  }
  .cs-container__inner .cs-headline {
    padding: 1em 0;
    margin: 1em 0;
}
}
