@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/component-spacing';
@import 'components/headline/mixin';

.#{$ns}headline {
    @include headline();

    @include component-spacing();

    &__title {
        @include headline-title();
    }

    &__subtitle {
        @include headline-subtitle();
    }
}
