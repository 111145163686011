@import 'config/variables';
@import 'vendors/include-media';
@import 'components/pseudoicon/mixin';

$category-links-dropdown_trigger-pseudoicon-width: 1rem !default;
$category-links-dropdown_trigger-pseudoicon-color: $color_primary-500 !default;
$category-links-dropdown_trigger-pseudoicon-side-offset: 0 !default;

.#{$ns}category-links-dropdown {
    $root: &;
    position: relative;

    &__trigger {
        @include pseudoicon(
            $width: $category-links-dropdown_trigger-pseudoicon-width,
            $color: $category-links-dropdown_trigger-pseudoicon-color,
            $color-hover: $category-links-dropdown_trigger-pseudoicon-color,
            $position-side-offset:
                $category-links-dropdown_trigger-pseudoicon-side-offset
        );

        &[aria-expanded='true'] {
            position: relative;
            z-index: 5;
            border-color: transparent;

            @include media('>=tablet') {
                position: static;
                z-index: auto;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: -1rem;
        right: -1rem;
        width: auto;
        padding-top: 4.8rem;
        overflow: hidden;
        height: 0;
        opacity: 0;
        transform: translateY(4.8rem);
        transition: opacity 50ms linear, transform 100ms ease-in-out;
        display: block !important;

        @include media('>=tablet') {
            position: static;
            overflow: visible;
            height: auto;
            opacity: 1;
            transform: none;
            padding: 0;
            transition: none;

            @include media('>=tablet') {
                display: flex !important;
            }
        }

        &[aria-hidden='false'] {
            z-index: 4;
            height: auto;
            opacity: 1;
            transform: translateY(0);
        }
    }

    &--no-subcats {
        #{$root}__content {
            @include media('<tablet') {
                position: static;
                transform: none;
                height: auto;
                opacity: 1;
                box-shadow: none;
                padding: 0;
            }
        }
    }
}
