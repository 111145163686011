/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
.cs-ie11-modal.modal-slide {
  left: 0;
  width: 100%; }
  .cs-ie11-modal.modal-slide .modal-inner-wrap {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 100%;
    max-width: 78rem;
    background-color: #fff;
    margin: auto; }
    .cs-ie11-modal.modal-slide .modal-inner-wrap .modal-content {
      padding: 0; }
  .cs-ie11-modal.modal-slide .action-close {
    background-size: 1.8rem 1.8rem;
    margin: 2.5rem; }
  .cs-ie11-modal.modal-slide .modal-footer {
    padding: 2rem 0;
    margin: 0 3rem;
    border-top: 0;
    display: flex;
    justify-content: center; }

.cs-ie11-modal._show .cs-ie11-modal__inner {
  display: flex; }

.cs-ie11-modal__inner {
  display: none;
  padding: 0 3rem;
  font-size: 1.4rem;
  color: #212121;
  height: 100%; }

.cs-ie11-modal__header, .cs-ie11-modal__body {
  padding: 2rem 0; }

.cs-ie11-modal__header {
  display: flex;
  flex-direction: column;
  align-items: center; }

.cs-ie11-modal__header-icon {
  width: 7rem;
  height: 7rem;
  margin-bottom: 2rem; }

.cs-ie11-modal__header-title {
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
  line-height: 100%;
  max-width: 100%; }

.cs-ie11-modal__body {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc; }

.cs-ie11-modal__paragraph {
  text-align: center;
  margin-bottom: 2rem; }
  .cs-ie11-modal__paragraph--title {
    font-size: 2rem;
    font-weight: 700; }

.cs-ie11-modal__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around; }

.cs-ie11-modal__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 130%;
  margin: 0; }

.cs-ie11-modal__item-logo {
  width: 3.8rem;
  height: 3.8rem;
  margin-bottom: 1rem;
  border: 0; }
  .ie11 .cs-ie11-modal__item-logo {
    width: 4rem;
    height: 4rem; }

.cs-ie11-modal__item-name {
  font-weight: 700; }

.ie11 .modal-popup:not(.cs-vide-player__modal) .modal-content {
  max-height: 100%; }
