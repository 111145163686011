/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-topbar {
  background: transparent;
  display: none;
  width: 100%;
  font-size: 1.2rem;
  transform: translateY(0);
  transition: transform 0.3s; }
  @media (min-width: 64em) {
    .cs-topbar {
      display: flex;
      z-index: 302; } }
  .cs-topbar--hidden {
    transform: translateY(-105%); }
  .page-print .cs-topbar {
    display: none; }
  .cs-topbar__wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    height: 2.9rem; }
    @media (min-width: 64em) {
      .cs-topbar__wrapper {
        flex-wrap: nowrap; } }
  .cs-topbar__item {
    color: #212121;
    display: flex;
    flex: 0 0 auto;
    white-space: nowrap;
    height: 100%; }
    .cs-topbar__item--usps {
      margin-right: auto; }
      @media (max-width: 79.99em) {
        .cs-topbar__item--usps {
          display: none !important; } }
      .page-wrapper--checkout .cs-topbar__item--usps {
        display: none !important; }
    .cs-topbar__item--hotline {
      align-items: center; }
      @media (max-width: 79.99em) {
        .cs-topbar__item--hotline {
          flex: 1;
          justify-content: flex-end; } }
    .cs-topbar__item--language {
      height: 100%; }
  .cs-topbar__list,
  .cs-topbar__item > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0; }
  .cs-topbar__list-item {
    margin-bottom: 0;
    height: 2.9rem;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 0.9rem;
    padding: 0 0.3rem 0 0; }
    @media (min-width: 64em) {
      .cs-topbar__list-item {
        padding: 0 1.3rem 0 0;
        margin-right: 1.7rem; } }
    .cs-topbar__list-item:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      content: '';
      border-right: 0.2rem solid #fff;
      transform: skew(-18deg); }
    .cs-topbar__list-item:last-child {
      margin-right: 0;
      padding-right: 0; }
      .cs-topbar__list-item:last-child:before {
        content: none; }
  .cs-topbar__text {
    color: #212121; }
    .cs-topbar__text__delivery:before {
      content: "\f0d1";
      font-family: "Font Awesome 5 Pro";
      font-weight: 600;
      color: #E5464B;
      margin-right: 10px; }
    .cs-topbar__text__financing:before {
      content: "\f555";
      font-family: "Font Awesome 5 Pro";
      font-weight: 600;
      color: #E5464B;
      margin-right: 10px; }
    .cs-topbar__text__warranty:before {
      content: "\f2f7";
      font-family: "Font Awesome 5 Pro";
      font-weight: 600;
      color: #E5464B;
      margin-right: 10px; }
  .cs-topbar__link {
    margin-right: 0.9rem; }
    .cs-topbar__link {
      color: #E5464B; }
      .cs-topbar__link:visited {
        color: #d21b37; }
      .cs-topbar__link:hover {
        color: #d21b37; }
      .cs-topbar__link:active, .cs-topbar__link:focus {
        color: #d21b37; }
    .cs-topbar__link:hover {
      color: #000; }
    @media (max-width: 63.99em) {
      .cs-topbar__link {
        display: none; } }
  .cs-topbar__hotline {
    display: flex;
    align-items: center; }
  .cs-topbar__phone-number {
    color: #000;
    font-weight: 700;
    margin-right: 0.9rem; }
    .cs-topbar__phone-number:hover {
      color: #000; }
