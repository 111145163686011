@import 'config/variables';
@import 'config/breakpoints';
@import 'include-media/dist/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/typography/mixin';
$image-teaser_cta-button-type: 'primary' !default;
$image-teaser_cta-button-icon-pos: 'right' !default;

.cs-image-teaser__content-wrapper--content-align-y-3 {
  .cs-image-teaser__slogan {
    text-shadow: 1px 1px 3px rgba(0,0,0,0.65);
  }

}



.category-wszystkie-obrabiarki {



  .cs-image-teaser__text-content {
      font-size: 1.2rem;
      color: $color_white;
  }


  .cs-image-teaser__cta {

    display: none;

  }

  .cs-image-teaser__content-wrapper.cs-image-teaser__content-wrapper--content-align-x-1.cs-image-teaser__content-wrapper--content-align-y-1 {
    //background: linear-gradient(45deg, rgba(0,0,0, 0.8) 10%, transparent 74%);
    background: linear-gradient(45deg, #333 0%, rgba(33, 33, 33, 0) 85%);
    mix-blend-mode: hard-light;
  }

  .cs-image-teaser__content-wrapper,
  .cs-image-teaser__wrapper--content-under.cs-image-teaser__content-wrapper {
    height: auto !important;
  }

  .cs-image-teaser__slide.cs-image-teaser__slide--image-uploaded {
    .cs-image-teaser__image.lazyload.swiper-lazy.lazyloaded {
      transition: all 0.2s ease;
    }

    &:hover {
      .cs-image-teaser__image.lazyload.swiper-lazy.lazyloaded {
        transform: scale(1.13);
        filter: brightness(0.9);
      }
    }

    .cs-image-teaser__figure {
      overflow: hidden;
    }
  }




  .cs-image-teaser__wrapper--content-under .cs-image-teaser__slide--scheme-light .cs-image-teaser__content {
    background: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: -6rem 0 0;
    padding: 0;

    .cs-image-teaser__slogan {
      font-size: 2rem;
      text-align: left;

    }
    // &__cta-button {
    //   @include button($type: $image-teaser_cta-button-type, $icon_pos: $image-teaser_cta-button-icon-pos);
    //   font-size: 5rem;
    //   @include media('>=phoneLg') {
    //     font-size: 2.15rem;
    //   }
    //   @include media('>=laptop') {
    //     font-size: 4.4rem;
    //   }
    //   @include media('>=laptopLg') {
    //     font-size: 4.6rem;
    //   }
    //   @include media('>=tv') {
    //     font-size: 4.9rem;
    //   }
    // }
    //zoom on hover
  }
}


.categorypath-firma-o-abplanalp {
  .cs-page-category__cc-top {
    div:nth-child(5),
    div:nth-child(6){
      .cs-image-teaser__description {
        font-size: 1.9rem;
      }
  }
    div:nth-child(8){
      .cs-image-teaser__aspect-ratio {
        padding-top: 0!important;
        height: 315px;
      }
      .cs-image-teaser__figure img {
        object-fit: contain;
        width: 100%;
        height: 300px;

      }
  }
    .cs-paragraph {
      font-size: 1.9rem;
    }
  }
    .cs-image-teaser__wrapper--content-over .cs-image-teaser__content-wrapper {
      display: flex;
      align-items: center;
    }
}
