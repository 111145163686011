@import 'config/variables';
@import 'config/breakpoints';
@import 'include-media/dist/include-media';
@import 'utils/component-spacing';
@import 'components/teaser/mixins';
@import 'components/button/mixin';
@import 'components/typography/mixin';
@import 'vendors/include-media';

// page-with-filter page-products categorypath-wiedza-automatyzacja category-automatyzacja catalog-category-view page-layout-1column
//
// page-with-filter page-products categorypath-zinasanai-automatizacija category-automatizacija catalog-category-view page-layout-1column
//
// page-with-filter page-products categorypath-par-uznemumu-par-abplanalp category-par-abplanalp catalog-category-view page-layout-1column

$color-bg-automatyzacja-bulletpoints: lighten(  $color_silver-chalice, 24% );

.categorypath-zinasanai-automatizacija,
.category-par-abplanalp {
   .prlxMain {
    width: 100%;
    height: 250px;
    background-position: center center;
    background-attachment: fixed;
    background-image: url("../images/paralax/firma-paralax.jpg");
    margin-bottom: 35px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
   }
.cs-image-teaser__content {
  background: transparent!important;
  margin: -1rem 0 0!important;
}

.cs-image-teaser:not(.cs-image-teaser--slider) .cs-image-teaser__slides {
  display: flex;
  justify-content: center;
  @include media('<=laptop') {
    display: block;
  }
}
.cs-container--teaser-and-text   {
  .cs-image-teaser__slogan {
     color: $color_mineshaft !important;
     font-size: 3rem;
 }

 .cs-image-teaser__description {
   color: $color_mineshaft !important;
   font-size: 2rem;
   text-align: justify;
   line-height: 1.4;
 }

}



  .bulletpoints {
    background-color: $color-bg-automatyzacja-bulletpoints;
    padding: 0rem 2rem 2rem 2rem;

    .col-sm-6 {
      padding: 0rem 2rem 2rem 2rem;    }

    h2 {
      color: inherit;
      text-transform: none;
      font-size: 1.6rem;
      margin: 4rem 0;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 0;
        list-style: none;
    }

    ul>li:before {
    content: "\f00c";
    color: $color_cerulean-blue;
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    margin: 0 1rem 0rem -2rem;
    width: 2rem;
      }
  }
}
