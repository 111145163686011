.cms-no-route {
  .cs-paragraph h2 {
    font-size: 30px;
    animation: rubberBand; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
    animation-delay: 1s;
    animation-iteration-count: infinite;

  }
}
