// In this mixin you can define your custom animation for buttons
// You can check examples of animations in http://ianlunn.github.io/Hover/ library

@mixin button_animation(
    $type: $button_default-type,
    $color_start: $button_primary-background,
    $color_end: $button_primary--hover-background,
    $icon_pos: $button_default-icon-pos
) {
    @if ($type == 'primary') and
        (($icon_pos == 'right') or ($icon_pos == 'left'))
    {
        &:hover,
        &:focus,
        &:active {
            background-color: $color_start !important;
        }
    }

    &:before,
    &:after {
        @if ($icon_pos == 'right') or ($icon_pos == 'left') {
            content: '';
            position: absolute;
            top: -#{$button_border-size};
            bottom: -#{$button_border-size};
        }

        @if ($icon_pos == 'right') {
            right: -#{$button_border-size};
        }

        @if ($icon_pos == 'left') {
            left: -#{$button_border-size};
        }
    }

    &:before {
        @if ($icon_pos == 'right') or ($icon_pos == 'left') {
            width: 5.5rem;
            border-radius: 0 $button_border-radius $button_border-radius 0;
            z-index: -1;
        }

        @if ($icon_pos == 'right') {
            background: linear-gradient(
                100deg,
                transparent 0%,
                transparent 15%,
                $color_end 15%,
                $color_end 100%
            );
        }

        @if ($icon_pos == 'left') {
            background: linear-gradient(
                80deg,
                $color_end 0%,
                $color_end 85%,
                transparent 85%,
                transparent 100%
            );
        }
    }

    &:after {
        @if ($icon_pos == 'right') or ($icon_pos == 'left') {
            width: 4rem;
            min-width: auto;
            background-color: $color_end;
            transition: width 0.25s linear;
            z-index: -2;
            border-radius: $button_border-radius;
        }
    }

    &:hover {
        @if ($icon_pos == 'right') or ($icon_pos == 'left') {
            &:after {
                @if ($button_border-size > 0) {
                    width: calc(100% + #{2 * $button_border-size});
                } @else {
                    width: 100%;
                }
            }
        }
    }
}
