@import 'config/variables';
@import 'components/button/mixin';
@import 'vendors/include-media';

.cms-home, .cms-home-ru {
.product-card-footer {
  .price {
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    min-height: 30px;
  }
}
  // .homevideo {
  //   margin: 0 auto;
  //   @include media('>=desktop') {
  //     margin: 2rem 0 0rem;
  //   }
  // }

  #ylMachines {
    .col-md-4 {
      display: flex;
    justify-content: center;
    }
  }
  .columns .column.main {
    padding: 0rem 0 0;
}
  .cs-container--headline {
    margin: 0rem 0 0rem;
    @include media('>=desktop') {
      margin: 2rem 0 1rem;
    }
    @include media('>=tv') {
      margin: 4rem 0 1rem;
    }

    .cs-headline {
    margin: 0 0 0rem;
    @include media('>=tv') {
      margin: 0 0 2rem;
    }
    }
  }

  .cs-products-grid__grid {
    .brand-attributes-redingo {
      display: none;
    }

    .cs-product-tile--grid .cs-product-tile__content {
      font-weight: 700;
    }

    .cs-products-grid {
        margin: 0rem !important;
    }

    .cs-links-block-addto__link-label, .cs-product-tile__stock, .configurable, .question-button, .cs-links-block-addto__item--wishlist, .cs-product-tile__swatches, .cs-product-tile__price {
      display: none;
    }
  }

}

.cs-image-teaser--items-in-row-1  {

  .cs-image-teaser__content {
    margin-top: 100% ;
  }
  .cs-image-teaser__text-content {

    .cs-image-teaser__slogan {
      font-size: 36px;
      animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
      animation-duration: 2s; /* don't forget to set a duration! */
      animation-delay: 3s;
      animation-iteration-count: infinite;
    }
    .cs-image-teaser__description {
      font-size: 26px;
    }
  }
}
