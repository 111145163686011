@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';



 .page-layout-blog-cms {

   .cs-category-links__list {
       flex: 1 1 auto;
       margin: -1rem 0 0 -2.5rem;
       text-align: center;
      font-size: 1.2rem;
   }

   .cs-category-links__list-item {
       display: inline-block;
       margin: 1rem 0 0 2.5rem;
       background: $color_cerulean-blue;
       padding: 0rem 3rem;
       color: white;
       a {
         color: white;
       }
   }


   .page-title {
     display: none;
   }

.cs-headline__title {
  text-transform: none !important;
}
    h2:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title) {
      margin: 3rem 0 2rem;
      font-size: 2rem;
      &:before {

        content: "\f05a";
        color: $color_cerulean-blue;
        font-family: "Font Awesome 5 Pro";
        font-size: 3rem !important;
        font-weight: 300;
        display: inline-block;
        margin-left: 0em;
        width: 50px;
        height: 50px;
        //font-size: 24px;
        //padding: 15px 10px;
        //background: lighten(  $color_silver-chalice, 24% );
        //border-radius: 50%;
        margin-right: 0px;
      }

    }

    h3:not(.cs-category-links__title):not(.cs-footer__section-title):not(.footer-tuplex):not(.newsletter-title)   {
        margin: 3rem 0 3rem;
        font-size: 1.7rem;
        &:before {

          content: "\f672";
          color: $color_cerulean-blue;
          font-family: "Font Awesome 5 Pro";
          font-size: 2.4rem !important;
          font-weight: 300;
          display: inline-block;
          margin-left: 0em;
          width: 50px;
          height: 50px;
          //font-size: 24px;
          //padding: 15px 10px;
          //background: lighten(  $color_silver-chalice, 24% );
          //border-radius: 50%;
          margin-right: 0px;
        }
      }


    font-size: 1.6rem;

    p {
      //text-align: justify;
      img {
        margin-top: 20px;
        width: 100%;
      }
    }


    #button {
      margin-top: 2rem;
    }


    .cs-button-component__span {

      min-width: 250px;
      font-size: 20px;
    }

    .cs-paragraph {

      img {
        width: 100%;
      }

      @include media('>=tablet') {
      margin: 0 0 0rem;
      }

      ul {
        margin-top: 2rem;
        list-style: none;
        padding: 3rem 3rem 3rem 6rem;
        background-color: lighten(  $color_silver-chalice, 24% );
      }

      ul > li:before {
        content: "\f00c";
        color: $color_cerulean-blue;
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        display: inline-block;
        margin-left: -1.9em;
        width: 1.9em;
      }
    }
  }


/////
cs-category-links__dropdown cs-category-links-dropdown




// .cs-category-links__list {
//
//   &:visited {
//       color: red;
//   }
//
//   font-size: 30px !important;
//
//   ul {
//     margin-top: 2rem;
//     list-style: none;
//     padding: 3rem 3rem 3rem 6rem;
//     background-color: $color_gallery;
//   }
//
//   ul > li:before {
//     content: "\f00c";
//     color: $color-tuplex-green;
//     font-family: "Font Awesome 5 Pro";
//     font-weight: 900;
//     display: inline-block;
//     margin-left: -1.9em;
//     width: 1.9em;
//   }
// }

.cs-category-links__list a {
  font-size: 30px !important
}

.cs-category-links__list-all {
    display: none;
}


.cs-category-links__list-item {
    display: inline-block;
    margin: 1rem 0 0 2.5rem;
}


.category-strefa-wiedzy {
  h2.cs-category-links__title {
    display: none;
  }
}

.elewacje-wentylowane, .strefa-wiedzy-szachownica {
  p:first-child {
    padding-top: 150px;

      @include media('<laptop') {
        padding-top: 50px;
      }
  }
}



.col-md-4, .col-md-9 {
  padding: 1rem;
}
.col-lg-3, .col-lg-6 {
  padding: 2rem;
}
