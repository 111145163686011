@import 'config/variables';

$free-shipping-indicator_padding: 1.5rem 2rem 2rem !default;
$free-shipping-indicator_font-size: 1.4rem !default;
$free-shipping-indicator_separator: 1px solid $color_border-500 !default;

$free-shipping-indicator_price-font-weight: 700 !default;

$free-shipping-indicator_fulfilled-icon-url: '../images/icons/messages/icon-success.svg' !default;
$free-shipping-indicator_fulfilled-icon-size: 2rem !default; // background-size format (2rem 1rem or 2rem === 2rem 2rem)

$free-shipping-indicator_bar-label-color: $color_success-500 !default;
$free-shipping-indicator_bar-label-font-weight: 700 !default;
$free-shipping-indicator_bar-label-spacing: 1rem !default;

$free-shipping-indicator_bar-height: 2rem !default;
$free-shipping-indicator_bar-padding: 0.4rem !default;
$free-shipping-indicator_bar-border: 1px solid $color_silver-chalice !default;
$free-shipping-indicator_bar-border-radius: $border-radius_base * 1.4 !default;

$free-shipping-indicator_bar-progress-wrapper-background: transparent !default;
$free-shipping-indicator_bar-progress-background: $color_success-500 !default;
$free-shipping-indicator_bar-progress-border-radius: $border-radius_base !default;
$free-shipping-indicator_bar-progress-transition: width 0.55s
    $transition-easing_slowdown-900 !default;

$free-shipping-indicator_icon-width: 3.4rem !default;
$free-shipping-indicator_icon-height: 2rem !default;
$free-shipping-indicator_icon-margin: 0 0 0 2.5rem !default;
$free-shipping-indicator_icon-color: $color_gray !default;
$free-shipping-indicator_icon-color--free-shipping-applied: $color_success-500 !default;

.#{$ns}free-shipping-indicator {
    $root: &;

    &__content {
        border-top: $free-shipping-indicator_separator;
        padding: $free-shipping-indicator_padding;
        color: $color_text-500;
        font-size: $free-shipping-indicator_font-size;
        text-align: left;
    }

    &__cell {
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        padding-right: 0 !important;
    }

    &__total-left-text {
        span {
            font-weight: $free-shipping-indicator_price-font-weight;
        }
    }

    &__total-fulfilled {
        padding-left: 2.8rem;
        background: url($free-shipping-indicator_fulfilled-icon-url) 0 50%
            no-repeat;
        background-size: $free-shipping-indicator_fulfilled-icon-size;
    }

    &__bar-wrapper {
        display: flex;
        align-items: center;
    }

    &__bar-label {
        flex-shrink: 0;
        color: $free-shipping-indicator_bar-label-color;
        font-weight: $free-shipping-indicator_bar-label-font-weight;

        &--start {
            margin-right: $free-shipping-indicator_bar-label-spacing;
        }

        &--end {
            margin-left: $free-shipping-indicator_bar-label-spacing;
        }
    }

    &__bar {
        width: 100%;
        height: $free-shipping-indicator_bar-height;
        padding: $free-shipping-indicator_bar-padding;
        border: $free-shipping-indicator_bar-border;
        border-radius: $free-shipping-indicator_bar-border-radius;
    }

    &__bar-progress-wrapper {
        height: 100%;
        background: $free-shipping-indicator_bar-progress-wrapper-background;
        border-radius: $free-shipping-indicator_bar-progress-border-radius;
    }

    &__bar-progress {
        height: 100%;
        width: 0;
        background: $free-shipping-indicator_bar-progress-background;
        border-radius: $free-shipping-indicator_bar-progress-border-radius;
        transition: $free-shipping-indicator_bar-progress-transition;
    }

    &__icon {
        flex-shrink: 0;
        width: $free-shipping-indicator_icon-width;
        height: $free-shipping-indicator_icon-height;
        margin: $free-shipping-indicator_icon-margin;
        fill: $free-shipping-indicator_icon-color;
        transition: fill $transition-default-time;

        #{$root}__bar-wrapper--free-shipping-active & {
            fill: $free-shipping-indicator_icon-color--free-shipping-applied;
        }
    }
}
