/* stylelint-disable block-no-empty */
@mixin badge_hook($type) {
}

@mixin badge_type-hook($type) {
}

@mixin badge_span-hook($type) {
}
/* stylelint-enable */
