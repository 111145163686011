/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-category-links {
  font-size: 1rem;
  clear: both;
  padding: 2em 0;
  margin: 2em 0;
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-category-links {
      margin: 0 0 2rem; } }
  .cs-category-links--skip-spacing {
    margin: 0; }
  .cs-category-links__title {
    display: block;
    text-align: center;
    align-items: center;
    margin: 0;
    font-size: 2.6em;
    font-weight: 700;
    color: #212121;
    text-transform: uppercase;
    text-align: left; }
    @media (min-width: 48em) {
      .cs-category-links__title {
        display: flex; } }
    .cs-category-links__title:before, .cs-category-links__title:after {
      content: '';
      display: none;
      height: 2px;
      flex-grow: 1;
      background-color: #E5464B; }
      @media (min-width: 48em) {
        .cs-category-links__title:before, .cs-category-links__title:after {
          display: flex; } }
    .cs-category-links__title:before {
      margin-right: 0.6em; }
    .cs-category-links__title:after {
      margin-left: 0.6em; }
    .cs-category-links__title:before, .cs-category-links__title:after {
      display: none; }
    @media (min-width: 48em) {
      .cs-category-links__title {
        text-align: center;
        margin-bottom: 1rem; }
        .cs-category-links__title:before, .cs-category-links__title:after {
          display: flex; } }
  .cs-category-links__subtitle {
    font-size: 1.2em;
    padding: 1.5rem 1rem 1.5rem 0;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer; }
    @media (min-width: 48em) {
      .cs-category-links__subtitle {
        display: none; } }
  .cs-category-links__list-wrapper {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3); }
    @media (min-width: 48em) {
      .cs-category-links__list-wrapper {
        display: flex;
        background: transparent;
        border-radius: 0;
        box-shadow: none; } }
    @media (min-width: 48em) {
      .cs-category-links--no-subcats .cs-category-links__list-wrapper {
        justify-content: flex-end; } }
  .cs-category-links__list, .cs-category-links__list-all {
    margin: 0;
    padding: 0;
    list-style: none; }
  @media (min-width: 48em) {
    .cs-category-links__list {
      flex: 1 1 auto;
      margin: -1rem 0 0 -2.5rem; } }
  .cs-category-links__list-item {
    margin: 0; }
    @media (min-width: 48em) {
      .cs-category-links__list-item {
        display: inline-block;
        margin: 1rem 0 0 2.5rem; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-category-links__list-item--hide-tablet {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

@media (min-width: 64em) and (max-width: 79.99em) {
  .cs-category-links__list-item--hide-laptop {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }

@media (min-width: 80em) and (max-width: 89.99em) {
  .cs-category-links__list-item--hide-laptopLg {
    display: none !important;
    /* stylelint-disable-line declaration-no-important */ } }
    @media (min-width: 90em) {
      .cs-category-links__list-item--hide-desktop {
        display: none !important;
        /* stylelint-disable-line declaration-no-important */ } }
  .cs-category-links__list-all {
    margin: 0 0 0.5rem; }
    .cs-category-links--no-subcats .cs-category-links__list-all {
      text-transform: uppercase; }
    @media (min-width: 48em) {
      .cs-category-links__list-all {
        flex: 0 1 auto;
        border-left: 1px solid #dcdcdc;
        position: relative;
        margin: 0 0 0 1.5rem;
        padding: 0 0 0 1.5rem;
        text-transform: uppercase;
        border: none; }
        .cs-category-links__list-all:before {
          position: absolute;
          left: 0;
          top: 0.5rem;
          bottom: 0.5rem;
          content: '';
          border-left: 1px solid #dcdcdc; }
        .cs-category-links--no-subcats .cs-category-links__list-all {
          margin: 0;
          padding: 0; }
          .cs-category-links--no-subcats .cs-category-links__list-all:before {
            display: none; } }
    .cs-category-links__list-all-item {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0; }
      .cs-category-links__list-all-item .cs-category-links__link {
        flex: 1 0 auto;
        padding: 0 1rem 1rem; }
        @media (min-width: 48em) {
          .cs-category-links__list-all-item .cs-category-links__link {
            padding: 1.5rem 3rem 1.5rem 1.5rem; }
            .cs-category-links__list-all-item .cs-category-links__link:hover {
              border-radius: 3px;
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3); } }
    .cs-category-links__list-all-icon {
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-left: 1rem;
      width: 1.2rem;
      height: 1.2rem;
      vertical-align: middle;
      transform: translate(0, -50%);
      display: none; }
      @media (min-width: 48em) {
        .cs-category-links__list-all-icon {
          display: block; } }
  .cs-category-links__link {
    text-decoration: none;
    display: block;
    padding: 0 1rem 1rem;
    font-weight: 700;
    fill: #888;
    color: #212121;
    font-size: 1.2em; }
    @media (min-width: 48em) {
      .cs-category-links__link {
        padding: 1.5rem 0;
        white-space: nowrap; } }
    .cs-category-links__link:visited {
      color: #212121; }
    .cs-category-links__link:hover,
    .cs-category-links__link:hover .cs-category-links__qty {
      text-decoration: none;
      fill: #E5464B;
      color: #E5464B; }
    @media (max-width: 47.99em) {
      .cs-category-links--no-subcats .cs-category-links__link {
        padding: 0.5rem 0;
        flex: 0 0 auto; } }
  .cs-category-links__qty {
    margin-left: 0.5em;
    font-weight: 400;
    color: #888; }
    .cs-category-links__qty:before {
      content: '('; }
    .cs-category-links__qty:after {
      content: ')'; }
  .cs-category-links--no-subcats {
    margin-bottom: 1em; }
