/**
 * String to number converter
 * https://www.sassmeister.com/gist/9fa19d254864f33d4a80
 * @author Hugo Giraudel
 * @param {String | Number} $value - Value to be parsed
 * @return {Number}
 */

@function to-number($value) {
    @if type-of($value) == 'number' {
        @return $value;
    } @else if type-of($value) != 'string' {
        $_: log('Value for `to-number` should be a number or a string.');
    }

    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: (
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
    );

    @for $i from if($minus, 2, 1) through str-length($value) {
        $character: str-slice($value, $i, $i);

        @if not(index(map-keys($numbers), $character) or $character == '.') {
            @return to-length(
                if($minus, -$result, $result),
                str-slice($value, $i)
            );
        }

        @if ($character == '.') {
            $digits: 1;
        } @else if ($digits == 0) {
            $result: $result * 10 + map-get($numbers, $character);
        } @else {
            $digits: $digits * 10;
            $result: $result + map-get($numbers, $character) / $digits;
        }
    }

    @return if($minus, -$result, $result);
}
