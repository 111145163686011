/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.table {
  margin-bottom: 3rem;
  background-color: white; }

.table__header {
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600; }

.table__item {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 1px solid #dcdcdc; }
