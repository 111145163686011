/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.amlocator-store-desc .amlocator-schedule-container .amlocator-today:before {
  content: "\f017";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #212121;
  margin: 0 30px 0 0 !important;
  font-size: 16px;
  background: unset;
  line-height: 0.8; }

.amlocator-store-desc .-active .amlocator-schedule-container .amlocator-today:before {
  content: "\f017";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: white !important;
  margin: 0 30px 0 0 !important;
  font-size: 16px;
  background: unset;
  line-height: 0.8; }

.amlocator-store-desc .amlocator-title .amlocator-link {
  color: #212121;
  font-weight: 500; }

.amlocator-store-desc.-active .amlocator-today {
  color: #fff;
  background: #E5464B; }
  .amlocator-store-desc.-active .amlocator-today::before {
    content: "\f017";
    font-family: "Font Awesome 5 Pro";
    font-weight: 600;
    color: white !important;
    margin: 0 30px 0 0 !important;
    font-size: 16px;
    background: unset;
    line-height: 0.8; }
  .amlocator-store-desc.-active .amlocator-today.amlocator-arrow:before, .amlocator-store-desc.-active .amlocator-today .amlocator-arrow:after {
    content: "";
    position: absolute;
    top: 3px;
    display: inline-block;
    width: 9px;
    height: 2px;
    background-color: #ffffff;
    transition: all .2s ease; }
  .amlocator-store-desc.-active .amlocator-today.amlocator-arrow:before, .amlocator-store-desc.-active .amlocator-today .amlocator-arrow:before {
    content: "";
    position: absolute;
    top: 3px;
    display: inline-block;
    width: 9px;
    height: 2px;
    background-color: #ffffff;
    transition: all .2s ease; }

.amlocator-main-container .amlocator-wrapper .amlocator-schedule-container strong {
  margin-left: 6px; }

.amlocator-row {
  margin: 2rem 0; }

.amlocator-schedule-table .amlocator-cell .-day, .-time {
  line-height: 0; }

.amlocator-location-view h1, .amlocator-index-index h1 {
  margin-top: 2rem; }

.amlocator-location-view .columns .column.main, .amlocator-index-index .columns .column.main {
  padding: 1rem 0 0; }

.amlocator-location-view .cs-container__inner .cs-headline, .amlocator-index-index .cs-container__inner .cs-headline {
  padding: 1em 0;
  margin: 1em 0; }
