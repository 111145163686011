@import 'config/variables';
@import 'components/button/mixin';
@import 'vendors/include-media';
$color-bg-brands: lighten($color_alto, 10%);


  .brand-link-image  {
    img {
      max-width: 80px !important;
    }

  }



.cms-home {
  .brand-attributes-redingo {
    display: flex;
    justify-content: space-between;
    min-width: 153px;
    margin-top: -50px;
  }
}


.brands-index-all {
  #maincontent {
    background: $color-bg-brands;
  }

  .cs-brands-index,
  .cs-container--headline,
  .cs-container--usps,
  .cs-image-teaser__content {
    background: $color-bg-brands !important;
  }

  .cs-brands-index__icon {
    background: $color_white;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.10);
  }

  .cs-brands-index__img {
    max-width: 80%;
    @include media('<=laptop') {
        max-width: 90%;
    }
    @include media('<=phoneLg') {
        max-width: 95%;
    }
  }
}
.cs-authorization-link__name {
  margin-right: 10px;
}
