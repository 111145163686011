@import 'config/variables';
@import 'vendors/include-media';

$video-player_background-color: $color_background-200 !default;

.#{$ns}video-player {
    $root: &;
    max-height: calc(100vh - 12rem);

    &__wrapper {
        display: none;

        #{$root}__modal & {
            display: block;
        }
    }

    &__modal {
        display: block;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;

        &._show {
            .modal-inner-wrap {
                transform: translateY(-50%);
            }
        }

        @include media('<tablet') {
            &._show {
                left: 0;

                .action-close:before {
                    font-size: 5.5rem;
                    line-height: 5.5rem;
                }
            }
        }

        .modal-inner-wrap {
            overflow: hidden;
            top: 50%;

            @include media('<tablet') {
                width: 100% !important;
                background: $video-player_background-color;
                padding: 1rem;
                box-sizing: border-box;
            }

            @include media('>=tablet') {
                width: 98vw;
                max-height: 94vh !important;
                margin: auto !important;
                padding: 3rem;
            }

            @include media('>=laptop') {
                width: 86vw;
            }
        }

        .#{$ns}modal__header {
            width: 100%;
            margin: 0;
            text-align: right;
            padding: 0 !important;
            box-sizing: border-box;

            @include media('<tablet') {
                min-height: 4.5rem;
            }
        }

        .#{$ns}modal__close {
            margin: 0 !important;
        }

        .#{$ns}modal__content {
            width: 100%;
            box-sizing: border-box;
            padding: 0 !important;
        }
    }
}
