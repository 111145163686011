@import 'config/variables';
@import 'config/colors';

.table{
  margin-bottom: 3rem;
  background-color: white;
};
.table__header{
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  font-size: $font-size_headline-3;
  font-weight: 600;
};
.table__item{
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  border-bottom: 1px solid $color_alto;
};
