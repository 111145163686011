@import 'config/variables';
@import 'components/button/mixin';
@import 'vendors/include-media';

.brands-abplanalp {
  width: 46% !important;
  margin: 0 4% 3rem 0;
  @include media('<=tablet') {
    width: 100% !important;
    margin: 0 0 3rem 0;
  }
  



  &.left {
    border-right: 1px solid $color_alto;
    @include media('<=tablet') {
      border-right: unset;
    }
  }
}

.brands_opis_lead {
  font-size: 16px;
  text-align: justify;
}
